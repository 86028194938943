import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import PublicLayout from './Public'
import LoginLayout from './Login'
import MainLayout from './Main'
import menuOptions from '../menu'

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
}
let send = false

@withRouter
@connect(({ user, city }) => ({ user, city }))
class IndexLayout extends React.PureComponent {
  previousPath = ''

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { prevLocation } = prevProps
    if (location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const {
      children,
      location: { pathname, search },
      user,
      city,
    } = this.props

    // NProgress Management
    const currentPath = pathname + search
    if (currentPath !== this.previousPath) {
      NProgress.start()
    }

    setTimeout(() => {
      NProgress.done()
      this.previousPath = currentPath
    }, 300)

    // Layout Rendering
    const getLayout = () => {
      if (pathname === '/') {
        return 'public'
      }
      if (/^\/user(?=\/|$)/i.test(pathname)) {
        return 'login'
      }
      return 'main'
    }

    const Container = Layouts[getLayout()]
    const isUserAuthorized = user.authorized
    const isLoginLayout = getLayout() === 'login'
    const BootstrappedLayout = () => {
      if (user.tokenReset && user.tokenReset !== '' && !send) {
        send = true

        return <Redirect to="/user/forgot" />
      }

      const urlSplit = pathname.split("/");

      if (urlSplit.length === 4 && urlSplit[2] === "loginMissionAnalitics" && isLoginLayout && isUserAuthorized) {
        return <Redirect to={`/admin/analiticsByMision/${urlSplit[3]}`} />
      }

      if (!isLoginLayout && !isUserAuthorized) {
        send = false
        const { location } = this.props
        const urlPDF = location.pathname.substr(1, 6)
        if (
          location.pathname !== '/Activations/bogota' &&
          location.pathname !== '/Activations/medellin' &&
          location.pathname !== '/Activations/mexico' &&
          location.pathname !== '/Activations/cali' &&
          location.pathname !== '/Activations/vietnam' &&
          urlPDF !== 'Downlo' &&
          urlPDF !== 'reset/'
        ) {
          return <Redirect to="/user/login" />
        }
      }
      if (isLoginLayout && isUserAuthorized) {
        send = false
        const permissions = user.permissions.split(',')
        const menuOptionsMenu = menuOptions[city.type_menu || 'STORE'].filter(
          (option) => option.url !== '',
        )
        const sectionObject = menuOptionsMenu.filter((p) => p.key === permissions[0])

        if (sectionObject.length > 0) {
          if (sectionObject[0].url) {
            return <Redirect to={sectionObject[0].url} />
          }
          if (sectionObject[0].children.length > 0) {
            return <Redirect to={sectionObject[0].children[0].url} />
          }
        }
        return <Redirect to="/user/login" />
      }
      return <Container>{children}</Container>
    }

    return (
      <Fragment>
        <Helmet titleTemplate="Admin | %s" title="Quqo" />
        {BootstrappedLayout()}
      </Fragment>
    )
  }
}

export default IndexLayout
