import { notification } from 'antd'
import axios from 'axios'
import { setUserProperty } from 'utils/tracker'
import axiosInstanceQuqo from './handlingPetitions'
import menuOptions from '../menu'

const JSONParsebaseURL = JSON.parse(process.env.REACT_APP_API_URL_V2);
const baseURLUse = localStorage.getItem('base_url_use') || "prod";

const baseURL = JSONParsebaseURL[baseURLUse];

const urlUsersV2 = `${baseURL}/admin`
const urlUsersGroup = `${baseURL}/admin/user`
const urlAuth = `${baseURL}/auth`

export async function login(email, password) {
  const objetoJSON = {
    credential: email,
    password,
  }

  return axios
    .post(`${urlAuth}/login`, objetoJSON)
    .then((response) => {
      const USER_DATA = response.data.data.user
      const PROVIDER_DATA = response.data.data.user.provider
      // const STORE_DATA = response.data.data.user.stores[0]

      let status = false

      const permissions = USER_DATA.user_role.permissions.split(',')

      let permissionsADMIN = []
      const permissionsNoViews = []
      let continueLogin = false

      const logOutSection = () => {
        logout()
        localStorage.clear()
        notification.warning({
          description: 'El usuario no cuenta con permisos para acceder al administrador',
        })
      }

      const objProperties = {
        city: 1,
        name: USER_DATA.name,
        country: PROVIDER_DATA.country,
        id: USER_DATA.id,
        email: USER_DATA.email,
        phone: USER_DATA.mobile_phone,
        provider_id: PROVIDER_DATA.id,
        role: USER_DATA.role,
      }

      const payload = {
        id: USER_DATA.id,
        name: USER_DATA.name,
        email: USER_DATA.email,
        avatar: USER_DATA.profile_pic,
        role: USER_DATA.role,
        city_id: 1,
        provider_name: PROVIDER_DATA.name,
        app_reference: USER_DATA.app_reference,
        id_provider: PROVIDER_DATA.id,
        country: PROVIDER_DATA.country,
        token: `Bearer ${response.data.data.token}`,
        permissions: "",
        authorized: true,
      }

      setUserProperty(objProperties)

      if (permissions.length === 0) {
        logOutSection()
      } else {
        let validSections = false

        permissions.forEach((permission) => {
          menuOptions.STORE.forEach((option) => {
            if (permission.replace('_WRITE', '') === option.key) {
              continueLogin = true
              validSections = true
            }
          })
        })

        if (menuOptions.STORE.length > 0 && permissions.length > 0 && validSections) {
          menuOptions.STORE.forEach((section) => {
            const exist = permissions.filter((permission) => permission === section.key)

            const existWrite = permissions.filter(
              (permission) => permission === `${section.key}_WRITE`,
            )
            if (exist.length > 0) {
              permissionsADMIN.push(section.key)
            }

            if (existWrite.length > 0) {
              permissionsNoViews.push(`${section.key}_WRITE`)
            }
          })

          menuOptions.Q2C.forEach((section) => {
            if (permissions.filter((permission) => permission === section.key)) {
              continueLogin = true
              permissionsADMIN.push(section.key)
            }
          })
        }

        permissionsADMIN = permissionsADMIN.concat(permissionsNoViews)


        if (continueLogin && validSections) {
          payload.permissions = `${permissionsADMIN.toString()}`;

          localStorage.setItem('sesion', JSON.stringify(payload))
          status = true
        } else {
          logOutSection()
        }
      }

      return status
    })
    .catch((error) => {
      console.log("error:", error)
      notification.warning({ description: "error.response.data.message" })
    })
}

export function resetPassword(data) {
  return new Promise((resolve) => {
    axios
      .post(`${urlAuth}/codePasswordReset`, data)
      .then((reponse) => resolve(reponse.data.data))
      .catch((error) => {
        throw new Error(error)
      })
  })
}

export function verifyCodePasswordReset(data) {
  return new Promise((resolve) => {
    axios
      .post(`${urlAuth}/verifyCodePasswordReset`, data)
      .then((reponse) => resolve(reponse.data))
      .catch((error) => {
        resolve(error)
      })
  })
}

export async function currentAccount() {
  function getCurrentUser() {
    return new Promise((resolve) => {
      const userData = localStorage.getItem('sesion')
        ? JSON.parse(localStorage.getItem('sesion'))
        : {}

      if (userData) {
        resolve(userData)
      } else {
        resolve(null)
      }
    })
  }
  return getCurrentUser()
}

export async function logout() {
  const userData = localStorage.getItem('sesion') ? JSON.parse(localStorage.getItem('sesion')) : {}

  if (userData.token) {
    axiosInstanceQuqo.get(`${baseURL}/logout`).then(() => {
      localStorage.clear()
    })
  } else {
    localStorage.clear()
  }
}

export async function getInfoUserSession() {
  const url = `${baseURL}/auth/tokenSession`
  return axiosInstanceQuqo.get(url).then((response) => response.data.data)
}

export async function getAllUsers(citySelected, Offset, limit, field, value, status, currentLocalityIDFilter) {
  let filterStatus = ""

  if (status != null) {
    filterStatus = `&status=${status}`
  }

  if (currentLocalityIDFilter != null) {
    filterStatus = `&locality=${currentLocalityIDFilter}`
  }

  if (citySelected) {
    const url = `${urlUsersGroup}/getAll/${citySelected}?limit=${limit}&Offset=${Offset}&field=${field}&text=${value}${filterStatus}&detail=true`
    return axiosInstanceQuqo.get(url).then((response) => response.data.data)
  }

  return null
}

export async function getAllByRole(role, providerID) {
  const url = `${urlUsersGroup}/getAllByRole/${role}/${providerID}`
  return axiosInstanceQuqo.get(url).then((response) => response.data.data)
}

export async function editUser(dataUser) {
  return axiosInstanceQuqo
    .put(`${urlUsersGroup}/editUser`, dataUser)
    .then((response) => response)
    .catch((error) => {
      throw new Error(error)
    })
}

export async function changeRole(data, role) {
  return axiosInstanceQuqo
    .put(`${urlUsersGroup}/changeRol/${data.id}/${role}`)
    .then((response) => response.data.data)
    .catch((error) => {
      throw new Error(error)
    })
}

export async function userChangePermissionsAdmin(data) {
  return axiosInstanceQuqo
    .put(`${urlUsersGroup}/permissions`, data)
    .then((response) => response.data.data)
    .catch((error) => {
      throw new Error(error)
    })
}

export async function changeReferralCodeService(data, valueInput) {
  const url = `${urlUsersV2}/user/changeReferralCode`
  const dataRequest = {
    user_id: data.id,
    referral: valueInput,
  }
  return axiosInstanceQuqo
    .put(url, dataRequest)
    .then((response) => response)
    .catch((error) => {
      throw new Error(error)
    })
}

export async function changeReferralUserService(data, valueInput) {
  const dataRequest = {
    user_id: data.id,
    referral: valueInput,
  }

  return axiosInstanceQuqo
    .put(`${urlUsersV2}/user/changeReferralUser`, dataRequest)
    .then((response) => response)
    .catch((error) => {
      throw new Error(error)
    })
}

export async function changeStatusUser(idUser, status) {
  return axiosInstanceQuqo
    .put(`${urlUsersGroup}/changeUserStatus/${idUser}/${status}`)
    .then((response) => response)
    .catch((error) => {
      throw new Error(error)
    })
}

export function sendToServerPasswordService(data) {
  return new Promise((resolve) => {
    axios
      .post(`${urlAuth}/passwordReset`, data)
      .then((reponse) => resolve(reponse.data.data))
      .catch((error) => {
        throw new Error(error)
      })
  })
}

export function ResetPasswordService(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${urlAuth}/sendEmailPasswordReset`, data)
      .then(() => resolve())
      .catch(() => reject())
  })
}

export function changeLTLGService(data) {
  return new Promise((resolve, reject) => {
    axiosInstanceQuqo
      .put(`${urlUsersV2}/user/changeLonLat`, data)
      .then(() => resolve())
      .catch(() => reject())
  })
}

export function GetUserById(userID) {
  return new Promise((resolve) => {
    axiosInstanceQuqo
      .get(`${urlUsersGroup}/getUsers/${userID}`)
      .then((responseProduct) => resolve(responseProduct.data.data))
      .catch((error) => {
        throw new Error(error)
      })
  })
}

export function resetPasswordService(data) {
  return new Promise((resolve, reject) => {
    axiosInstanceQuqo
      .post(`${baseURL}/changePassword`, data)
      .then((response) => resolve(response.data))
      .catch(() => reject())
  })
}

export function GetRoles() {
  return new Promise((resolve) => {
    axiosInstanceQuqo
      .get(`${urlUsersGroup}/roles`)
      .then((responseProduct) => resolve(responseProduct.data.data))
      .catch((error) => {
        throw new Error(error)
      })
  })
}

export function createNewRole(data) {
  return new Promise((resolve) => {
    axiosInstanceQuqo
      .post(`${urlUsersGroup}/role`, data)
      .then((responseProduct) => resolve(responseProduct.data.data))
      .catch((error) => {
        throw new Error(error)
      })
  })
}

export function updateRole(data) {
  return new Promise((resolve) => {
    axiosInstanceQuqo
      .put(`${urlUsersGroup}/role`, data)
      .then((responseProduct) => resolve(responseProduct.data.data))
      .catch((error) => {
        throw new Error(error)
      })
  })
}

// Services Q2C
export function getDocumentTypes(country) {
  return new Promise((resolve) => {
    axiosInstanceQuqo
      .get(`${baseURL}/user/documentTypes?country=${country}`)
      .then((responseProduct) => resolve(responseProduct.data.data))
      .catch((error) => {
        throw new Error(error)
      })
  })
}

export function getUsersQ2C(_, offset, field, value) {
  let filterData = {}

  if (value != null) {
    filterData = { '${field} $ilike': '${value}' }
  }
  return new Promise((resolve) => {
    axiosInstanceQuqo
      .get(`${urlUsersGroup}/getAllQtc?q={"limit":10,"offset":${offset},"filter":${filterData}}`)
      .then((responseProduct) => resolve(responseProduct.data.data))
      .catch((error) => {
        throw new Error(error)
      })
  })
}

export function filterUserQ2C(credential) {
  return new Promise((resolve) => {
    axiosInstanceQuqo
      .get(
        `${urlUsersGroup}/getAllQtc?q={"limit":1,"offset":0,"filter":{"credential $ilike":"${credential}"}}`,
      )
      .then((responseProduct) => {
        if (responseProduct.data.data.users && responseProduct.data.data.users.length > 0) {
          resolve(responseProduct.data.data.users[0])
        }
        resolve({})
      })
      .catch((error) => {
        throw new Error(error)
      })
  })
}

export function changeUserQ2CtoStore(data) {
  return new Promise((resolve) => {
    axiosInstanceQuqo
      .post(`${baseURL}/admin/customers/changeQtcToStore`, data)
      .then((responseProduct) => resolve(responseProduct.data))
      .catch((error) => {
        throw new Error(error)
      })
  })
}

// Lista blanca de usuarios

export function getWhitelistUsersService(keySearch, valueSearch) {
  return new Promise((resolve) => {
    axiosInstanceQuqo
      .get(
        `${baseURL}/admin/whitelist/?q={"limit":100000,"filter":{"${keySearch} $ilike":"${valueSearch}"}}`,
      )
      .then((response) => resolve(response.data))
      .catch((error) => {
        throw new Error(error)
      })
  })
}

export function createWhitelistUsersService(data) {
  return new Promise((resolve) => {
    axiosInstanceQuqo
      .post(`${baseURL}/admin/whitelist/`, data)
      .then((response) => resolve(response.data))
      .catch((error) => {
        throw new Error(error)
      })
  })
}

export function editWhitelistUsersService(data, registerID) {
  return new Promise((resolve) => {
    axiosInstanceQuqo
      .put(`${baseURL}/admin/whitelist/id/${registerID}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => {
        throw new Error(error)
      })
  })
}

export function deleteWhitelistUsersService(userID) {
  return new Promise((resolve) => {
    axiosInstanceQuqo
      .delete(`${baseURL}/admin/whitelist/id/${userID}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        throw new Error(error)
      })
  })
}

// coordinadores Services

export function getlistUsersCoordinatorsService(providerID) {
  return new Promise((resolve) => {
    axiosInstanceQuqo
      .get(
        `${baseURL}/admin/coordinatorUsers/?q={"limit":100000,"filter":{"provider_id $eq":${providerID}}}`,
      )
      .then((response) => resolve(response.data.data))
      .catch((error) => {
        throw new Error(error)
      })
  })
}

export function createUserCoordinatorService(data) {
  return new Promise((resolve) => {
    axiosInstanceQuqo
      .post(`${baseURL}/admin/coordinatorUsers/`, data)
      .then((response) => resolve(response))
      .catch((error) => {
        throw new Error(error)
      })
  })
}

// Usuarios de providers hijos

export function createInheritedProviderUserService(data) {
  return new Promise((resolve) => {
    axiosInstanceQuqo
      .post(`${baseURL}/admin/inheritedProviders/users`, data)
      .then((response) => resolve(response))
      .catch((error) => {
        throw new Error(error)
      })
  })
}

export function getCoverageStoreService(storeID) {
  return new Promise((resolve, reject) => {
    axiosInstanceQuqo
      .get(`${baseURL}/admin/stores/coverageByStore/${storeID}`)
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error)
      })
  })
}
