import React from 'react'
import { Menu, Dropdown } from 'antd'
import { connect } from 'react-redux'
import styles from './style.module.scss'

@connect(({ settings }) => ({ settings }))
class LanguageSelector extends React.Component {

  changeLang = ({ key }) => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'locale',
        value: key,
      },
    })
  }

  returnB = (key) => {
    switch (key) {
      case "es-CO": return  <>🇨🇴 Es</>;
      case "en-US": return  <>🇬🇧 En</>;
      case "vi-VN": return  <>🇻🇳 Vi</>;
      default:
        break;
    }
    return null
  }

  render() {
    const {
      settings: { locale },
    } = this.props
    const language = locale
    const langMenu = (
      <Menu className={styles.menu} selectedKeys={[locale]} onClick={this.changeLang}>
        <Menu.Item key="es-CO">
          <span role="img" aria-label="Español" className="mr-2">
          🇨🇴
          </span>
          Español
        </Menu.Item>
        <Menu.Item key="en-US">
          <span role="img" aria-label="English" className="mr-2">
            🇬🇧
          </span>
          English
        </Menu.Item>
        <Menu.Item key="vi-VN">
          <span role="img" aria-label="Vietnamita" className="mr-2">
            🇻🇳
          </span>
          Vietnamita
        </Menu.Item>
      </Menu>
    )
    return (
      <>
        <Dropdown overlay={langMenu} trigger={['click']}>
          <div className={styles.dropdown}>
            <strong className="text-uppercase">{this.returnB(language)}</strong>
          </div>
        </Dropdown>
      </>
    )
  }
}

export default LanguageSelector
