/* eslint-disable import/no-mutable-exports */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getInfoUserSession, logout } from 'services/user'
import { setUserProperty } from 'utils/tracker'
import menuOptions from '../../menu'
import actions from './actions'

export let userCookieData = {}

async function getLeftMenuData() {
  const menuLeftData = []
  const userCookie = localStorage.getItem('sesion')
    ? JSON.parse(localStorage.getItem('sesion'))
    : {}
  const cityCookie = localStorage.getItem('userCity')
    ? JSON.parse(localStorage.getItem('userCity'))
    : {}

  userCookieData = userCookie

  let permissionsADMIN = []
  const permissionsNoViews = []
  let permissionsString = ''

  await getInfoUserSession().then((user) => {
    
    const newPermissions = user.user_role.permissions.split(',')

    const logOutSection = () => {
      logout()
      localStorage.clear()
      window.location.reload()
    }

    if (newPermissions.length === 0) {
      logOutSection()
    } else {
      const USER_DATA = user
      const PROVIDER_DATA = user.provider
      // const STORE_DATA = user.stores[0]

      const objProperties = {
        city: 1,
        name: USER_DATA.name,
        country: PROVIDER_DATA.country,
        id: USER_DATA.id,
        email: USER_DATA.email,
        phone: USER_DATA.mobilephone,
        provider_id: PROVIDER_DATA.id,
        role: USER_DATA.role,
      }

      setUserProperty(objProperties)

      if (menuOptions.STORE.length > 0 && newPermissions.length > 0) {
        menuOptions.STORE.forEach((section) => {
          const exist = newPermissions.filter((permission) => permission === section.key)

          const existWrite = newPermissions.filter(
            (permission) => permission === `${section.key}_WRITE`,
          )
          if (exist.length > 0) {
            permissionsADMIN.push(section.key)
          }

          if (existWrite.length > 0) {
            permissionsNoViews.push(`${section.key}_WRITE`)
          }
        })

        const existProductDistributions = newPermissions.filter((permission) => permission === "PRODUCT_DISTRIBUTOR");

        if (existProductDistributions.length > 0) {
          permissionsADMIN.push("PRODUCT_DISTRIBUTOR")
        }

        menuOptions.Q2C.forEach((section) => {
          const exist = newPermissions.filter((permission) => permission === section.key)

          const existWrite = newPermissions.filter(
            (permission) => permission === `${section.key}_WRITE`,
          )
          if (exist.length > 0) {
            permissionsADMIN.push(section.key)
          }

          if (existWrite.length > 0) {
            permissionsNoViews.push(`${section.key}_WRITE`)
          }
        })
      }

      permissionsADMIN = permissionsADMIN.concat(permissionsNoViews)

      if (user.user_role && permissionsADMIN !== '') {
        permissionsString = permissionsADMIN.toString()

        userCookie.permissions = permissionsADMIN.toString()
        userCookie.country = PROVIDER_DATA.country;
        userCookie.app_reference= USER_DATA.app_reference;
        
        localStorage.setItem('sesion', JSON.stringify(userCookie))
      } else {
        logOutSection()
      }

      if (permissionsADMIN.length >= 1 && menuOptions !== null) {
        const menuOptionsMenu = menuOptions[cityCookie.type_menu || 'STORE'].filter(
          (option) => option.url !== '',
        )
        menuOptionsMenu.forEach((m) => {
          permissionsADMIN.forEach((p) => {
            if (p === m.key) menuLeftData.push(m)
          })
        })
      }
    }
  })

  const data = {
    menuLeftData,
    permissions: permissionsString,
  }

  return data
}

export function* GET_DATA() {
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuLeftData: [],
    },
  })
  const response = yield call(getLeftMenuData)

  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuLeftData: response.menuLeftData,
    },
  })

  yield put({
    type: 'user/SET_STATE',
    payload: {
      permissions: response.permissions,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DATA, GET_DATA),
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
