/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import { useSelector } from 'react-redux'
import { getProvidersService } from '../../../services/providers'

const { Option, OptGroup } = Select

function SelectedProvider({ changeCity, country, appReference, showOnlyProvidersByCountry }) {
  const { city } = useSelector((state) => ({ city: state.city }))

  const [arrayOptions, setArrayOptions] = useState([])
  const [allProviders, setAllProviders] = useState([])
  const [citySelected, setCitySelected] = useState(city)

  useEffect(() => {
    getProvidersService().then((providers) => {
      if (!showOnlyProvidersByCountry) {
        buildArraySelect(providers)
      } else {
        const arrayProviders = []

        providers.forEach((p) => {
          if (p.country === country) {
            arrayProviders.push(p)
          }
        })

        buildArraySelect(arrayProviders)
      }
    })
  }, [])

  useEffect(() => {
    if (citySelected.city !== city.city) {
      setCitySelected(city)
    }
  }, [citySelected.city !== city.city])

  const buildArraySelect = (providers) => {
    const parentsProviders = providers.filter((provider) => provider.parent_provider_id === null)

    const arrayProviders = []

    if (parentsProviders.length > 0) {
      parentsProviders.forEach((parent) => {
        const arrayChilds = []

        providers.forEach((p) => {
          if (
            (p.app_reference === appReference || appReference === 'quqo') &&
            p.parent_provider_id === parent.id_provider
          ) {
            arrayChilds.push(p)
          }
        })

        parent.childs = arrayChilds

        arrayProviders.push(parent)
      })
    } else {
      arrayProviders.push({
        city: '',
        id_provider: null,
        childs: providers,
      })
    }
    setAllProviders(providers)
    setArrayOptions(arrayProviders)
  }

  const handleChange = (value) => {
    const optionSelected = allProviders.filter((option) => option.id_provider === Number(value))[0]

    setCitySelected(optionSelected)

    localStorage.setItem('userCity', JSON.stringify(optionSelected))

    changeCity({
      type: 'city/SET_STATE',
      payload: optionSelected,
    })
  }

  return (
    <Select
      notFoundContent=""
      showArrow
      showSearch
      // filterOption={(input, option) => {

      //   console.log(option.props.children)
      //   return true;
      // }

      // }
      filterOption={(input, option) => {
        const { children } = option.props;

        if (option.type.isSelectOptGroup) {
          return children.includes(child => child.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0);
        }
        return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      value={citySelected.city}
      style={{ width: 400 }}
      onChange={handleChange}
      maxTagCount={100}
    >

      {arrayOptions.map((parent) => (
        <OptGroup label={parent.city}>
          {parent.id !== null && appReference === 'quqo' ? (
            <Option key={parent.id_provider}>{parent.city}</Option>
          ) : null}
          {parent.childs.map((h) => (
            <Option key={h.id_provider}>{h.city}</Option>
          ))}
        </OptGroup>
      ))}
    </Select>
  )
}

export default SelectedProvider
