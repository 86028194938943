/* eslint-disable react/react-in-jsx-scope */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { login, currentAccount, logout, resetPassword } from 'services/user'
import actions from './actions'

export function* LOGIN({ payload }) {
  const { email, password } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(login, email, password)

  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield put({
      type: 'city/LOAD_CURRENT_CITY',
    })
  }
}

export function* RESET_PASSWORD({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(resetPassword, payload)
  if (response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        tokenReset: response.token,
        emailReset: payload.email,
      },
    })
  }
}

export function* DELETE_TOKEN_RESET() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      tokenReset: null,
    },
  })
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(currentAccount)
  
  if (response) {
    yield put({
      type: 'user/SET_STATE',
      payload: response,
    })
    yield put({
      type: 'menu/GET_DATA',
    })
  }
}

export function* LOGOUT() {
  yield call(logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeEvery(actions.DELETE_TOKEN_RESET, DELETE_TOKEN_RESET),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(),
  ])
}
