import axios from 'axios'
import { store as reduxStore } from '../index'

// QUQO INSTANCE

const axiosInstanceQuqo = axios.create({
  baseURL: ''
})


// REQUEST
axiosInstanceQuqo.interceptors.request.use(
  request => requestHandler(request),
)

function returnToken() {
  return new Promise((resolve) => {
    const sesionStorage = localStorage.getItem('sesion');

    if (sesionStorage && JSON.parse(sesionStorage)) {
      const userToken = JSON.parse(sesionStorage).token;
      resolve(userToken)
    };
  })
}

export async function requestHandler(request) {
  request.headers.Authorization = await returnToken();

  request.headers.post['Content-Type'] = 'application/json'
  return request
}

// RESPONSE
axiosInstanceQuqo.interceptors.response.use(
  response => responseHandler(response),
  error => errorHandler(error),
)

const responseHandler = (response) => {
  return response
}

const errorHandler = (error) => {

  if (error.response.status === 403) {
    reduxStore.dispatch({
      type: 'user/LOGOUT',
    })
  }

  throw error
}
// FINISH RESPONSE HANDLER

export default axiosInstanceQuqo
