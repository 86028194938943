import antdData from 'antd/lib/locale-provider/en_US'
import localeData from 'react-intl/locale-data/en'

const messages = {
  'topBar.title': 'Administrator',
  'topBar.profileMenu.user': 'Username',
  'topBar.profileMenu.role': 'ROL',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.logout': 'Sign off',
  'topBar.profileMenu.ressetPassword': 'Change Password',
  'topBar.profileMenu.printIP': 'IP Printer',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.terms': "Terms & conditions",
  'topBar.profileMenu.sales_policies': "Policy complaint resolution process",
  'topBar.profileMenu.privacy_policies': "Privacy Policy",


  'footer.title': 'Version v',
  'footer.subTitle': 'Unlocking the potential of mom & pop stores in emerging markets',
  'footer.url': '© 2022 Quqo Back office',

  'authorize.not': 'Unauthorized access',
  'authorize.desNot': 'You do not have rights to access this page!',

  'login.language': 'Language',
  'login.iniciar': 'Log in',
  'login.welcome': 'Welcome',
  'login.welcomeQuqo': 'Welcome to Quqo Admin',
  'login.invalidEmail': 'Invalid email or password',
  'login.requiredEmail': 'The entry is not a valid email address',
  'login.requiredEmail2': 'Please enter your email address',
  'login.requiredpass': 'Please enter your password.',
  'login.forgetPassword': 'Did you forget your password?',
  'login.login': 'Log in',

  'resetPassword.title': 'UPDATE PASSWORD',
  'resetPassword.confirmOK': 'Your password has been updated successfully',
  'resetPassword.newPassword': 'Create your new password below',
  'resetPassword.actual': 'Current password',
  'resetPassword.new': 'New Password',
  'resetPassword.newConfirm': 'Confirm Password',
  'resetPassword.PleaseConfirm': 'Please confirm the password correctly.',
  'resetPassword.noConfirm': 'The two passwords you enter are inconsistent!',
  'resetPassword.actualPassword': 'Please enter the current password!',
  'resetPassword.continue': 'Continue',
  'resetPassword.backToLogin': 'Back To Login',
  'resetPassword.checkCode': 'Check Code',
  'resetPassword.emailCode1': 'We just sent a code to',
  'resetPassword.emailCode2': 'Please check your email for a message from the Quqo team and enter the code here.',
  'resetPassword.enterCode': 'Enter Code',
  'resetPassword.writeCode': 'Please enter the code that we send to your email',
  'resetPassword.VerifyYourIdentity': 'Verify your identity',
  'resetPassword.title2': 'Reset your password',
  'resetPassword.enterNewPassword': 'Please enter a new password',
  'resetPassword.repeatPassword': 'Repeat password',
  'resetPassword.PleaseRepeatPassword': 'Please repeat the password',

  'globals.mobilePhone': 'Phone number',
  'globals.attachments': 'Attachments',
  'globals.address': 'Address',
  'globals.detail': 'Detail',
  'globals.name': 'Name',
  'globals.email': 'Email',
  'globals.emailPhone': 'Email/Phone number',
  'globals.password': 'Password',
  'globals.credential': 'ID',
  'globals.StoreCredential': 'Store ID Number',
  'globals.category': 'Category',
  'globals.alert': 'Alert',
  'globals.alertError': 'Error',
  'globals.edit': 'Edit',
  'globals.provider': 'Provider',
  'globals.price': 'Price',
  'globals.TotalPrice': 'Total price',
  'globals.deadline': 'Delivery Date',
  'globals.store': 'Store',
  'globals.status': 'Status',
  'globals.SeeOrder': 'See order',
  'globals.send': 'Send',
  'globals.cancel': 'Cancel',
  'globals.ok': 'Yes',
  'globals.cancelModal': 'No',
  'globals.okSave': 'Save',
  'globals.cancelText': 'Cancel',
  'globals.total': 'Total',
  'globals.loading': 'Loading...',
  'globals.product': 'Product',
  'globals.initialAmount': 'Initial Quantity',
  'globals.finalAmount': 'Final Quantity',
  'globals.AmounttoReceive': 'Quantity to Receive',
  'globals.AmountReceive': 'Received Quantity',
  'globals.unitValue': 'Unit value',
  'globals.unitValueInitial': 'Initial unit value',
  'globals.unitValueFinal': 'Final unit value',
  'globals.productImage': 'Product image',
  'globals.orderHistory': 'Order History',
  'globals.orderStatusDetail': 'Picking',
  'globals.noChanges': 'It has no changes',
  'globals.columnsControlChanges.title': 'Change control',
  'globals.columnsControlChanges.date': 'Edition date',
  'globals.columnsControlChanges.responsable': 'Assigned',
  'globals.columnsControlChanges.reason': 'Reason',
  'globals.quantity': 'Quantity',
  'globals.points': 'Points',
  'globals.totalToPay': 'Total to pay',
  'globals.pilot': 'Pilot',
  'globals.status.all': 'All',
  'globals.status.pending': 'Pending Payment',
  'globals.status.confirm': 'Confirmed',
  'globals.status.cancel': 'Cancelled',
  'globals.status.transit': 'In transit',
  'globals.status.delivered': 'Delivered',
  'globals.status.reagended': 'Rescheduled',
  'globals.status.review': 'In Review',
  'globals.status.deliveryFailed': 'Failed Delivery',
  'globals.filterById': 'Filter by order id',
  'globals.update': 'Update',
  'globals.filter': 'Filter',
  'globals.description': 'Description',
  'globals.change': 'Change',
  'globals.zipcode': 'Postal Code',
  'globals.city': 'City',
  'globals.select': 'Select',
  'globals.create': 'Create',
  'globals.turnOff': 'Turn Off',
  'globals.type': 'Type',
  'globals.role': 'Role',
  'globals.accept': 'Accept',
  'globals.uploadFile': 'Click or drag the file to this area to load',
  'globals.date': 'Date',
  'globals.searchPlaceholder': 'Select the field...',
  'globals.addProductEan': 'EAN',
  'globals.textBoxSearch': 'Search',
  'globals.delete': 'Remove',
  'globals.return': 'Return',
  'globals.client': 'Customer',
  'globals.Remove': 'Remove',
  'globals.RemoveProducts': 'Remove products',
  'globals.searchProducts': 'Search orders by product',
  'globals.confirmRemoveProducts': 'Are you sure to remove the product from the selected orders?',
  'globals.searchProductsInput': 'Looking for products...',
  'globals.departament': 'Department',
  'globals.Save': 'Save',
  'globals.searchBy': 'Search By:',
  'globals.id': 'ID',
  'globals.required': 'This field is required.',
  'globals.allRequired': 'All fields are required',
  'globals.spanish': '🇨🇴 Spanish',
  'globals.english': '🇺🇸 English',
  'globals.vietnamita': '🇻🇳 Vietnamese',
  'globals.errorNotification': 'There was an error checking the data',
  'globals.finished': 'Finished',
  'globals.Detail': 'Detail',
  'globals.coverage': 'Coverage',
  'globals.orders': 'Orders',
  'globals.stops': 'Waypoints',
  'globals.download': 'Download in EXCEL',
  'globals.downloadLogInventory': 'Download in EXCEL log of process',
  'globals.orderID': 'Order ID',
  'globals.userID': 'User ID',
  'globals.options': 'Options',
  'globals.mudules': 'Security',
  'globals.Localidad': 'Location',
  'globals.searchOrder': 'Search order by ID',
  'globals.errorService': 'There was an error, try again ...',
  'globals.neighborhood': 'Neighborhood',
  'globals.ally': 'Ally',
  'globals.shipping': 'Shipping',
  'globals.quqoStore': 'Store',
  'globals.aprobar': 'Approve',
  'globals.ver': 'See',
  'globals.complete': 'To Complete',
  'globals.config': 'Config',
  'globals.show': 'Show',
  'globals.number': 'Number',
  'globals.discount': 'Discount',
  'globals.detailTC': 'TC detail',
  'globals.NoPermissions': 'No Permissions',
  'globals.commission': 'Commission',
  'globals.changeProvider': 'Change City',
  'globals.changetype': 'Change (Store - Q2C)',
  'globals.changeOrderStatus': 'Change order status',
  'globals.later': 'Later',
  'globals.noImage': 'This product has no image',
  'globals.confirm': 'Confirm',
  'globals.confirmDownload': 'Confirm download',
  'globals.screen': 'Screen',
  'globals.help': 'Help',
  'globals.units': 'Units',
  'globals.receive': 'Receive',
  'globals.seeUser': 'Check user',
  'globals.changeProvidersByCountryUser': 'Show providers from the users country',
  'globals.startDate': 'Start date',
  'globals.endTime': 'End date',
  'globals.selectDate': 'Select date',
  'globals.reset': 'Reset',
  'globals.downloadText': 'Download the order',
  'globals.updated': 'Updated',
  'globals.willUpdate': 'Will Update',
  'orders.order': 'Orders',
  'orders.title': 'Orders',
  'orders.subtitle': 'Quqo orders Information',
  'orders.filterByStatus': 'Filter by status',
  'orders.downloadPdf': 'Send PDFs by Date',
  'orders.downloadExcel': 'Send Excel file by Email',
  'orders.downloadExcelConfirm': 'Are you sure you want to receive Excel file with all orders by mail?',
  'orders.sendPDF': 'Send PDF of the orders',
  'orders.selectDate': 'Select date',
  'orders.required.EAN': 'Please enter the product EAN!',
  'orders.required.email': 'Please enter the destination email!',
  'orders.neighborhood': 'Neighborhood',
  'orders.map': 'See map',
  'orders.statusStore': 'Store Detail',
  'orders.cash': 'Cash',
  'orders.zalopay': 'ZaloPay',
  'orders.linkToPay': 'link To Pay',
  'orders.tc': 'Credit card',
  'orders.noPaymentMethod': 'Does not have',
  'orders.OrderTotal': 'Payment Detail',
  'orders.TotalQuantity': 'Total quantity',
  'orders.PaidwithQuota': 'Total Amount to Pay',
  'orders.PaidwithPoints': 'Paid with Points',
  'orders.PointsUsed': 'Points Used',
  'orders.selectDateRequired': 'You must select a date to continue..',
  'orders.OrderDate': 'Order Date',
  'orders.PaymentMethod': 'Payment method',
  'orders.files': 'In few minutes you will receive an email with the files.',
  'orders.DiscountProducts': 'Product has been discounted',
  'orders.discountOK': 'The products of the order were discounted',
  'orders.OrderID': 'Order ID',
  'orders.SelectRes': 'Select a Responsible',
  'orders.totalOrders': 'TOTAL ORDERS',
  'orders.SelectTheReason': 'Select the reason',
  'orders.promisedDate': 'Scheduled date',
  'orders.insuffucientPoints': 'INSUFFICIENT POINTS',
  'orders.insuffucientPointsSub': 'The order was cancelled with points, currently the client doesnt have enough points to change the order status',
  'orders.textRequired': 'You must select a reason.',
  'orders.add': 'Add',
  'orders.replace': 'Replace',
  'orders.configuration': '(With additional Settings)',
  'orders.noConfiguration': 'It has no settings',
  'orders.delivery': 'Delivered',
  'orders.promise': 'Scheduled',
  'orders.discountByCoupon': 'Coupon discount',
  'orders.discounts': 'Discounts',
  'orders.pendingReview': 'Pending Review',
  'orders.approved': 'Approved',
  'orders.declined': 'Declined',
  'orders.reschedule': 'Reschedule order',
  'orders.rescheduleSub': 'Are you sure to reschedule this order?',
  'orders.rescheduleQuestion': 'Are you sure to reschedule this order because of',
  'orders.changeStatusOrderConform': 'Are you sure to mark this order as delivered?',
  'orders.changeStatusOrderTransit': 'Are you sure to change the status to In transit?',
  'orders.allowCancelOrders': 'Allow cancel orders',
  'orders.changeOrdersToTransit': 'Change orders to in transit',
  'orders.markOrderAsDelivered': 'Mark order as delivered',
  'orders.markOrderInTransit': 'Mark order in transit',
  'orders.changeStatusAll': 'Change status to selected orders',
  'orders.dateRange': 'Filter by range of delivery dates',
  'orders.numberInoviceMessage': 'Saving the invoice number.',
  'orders.numberInovice': '# Invoice',
  'orders.enterInoviceNumber': 'Enter the invoice number',
  'orders.successSaveInoviceNumber': 'The invoice number has been saved successfully.',
  'orders.seeOrdersAllProviders': 'see the orders of all providers',
  'orders.paymentMethod.CREDIT_CARD': 'Credit Card',
  'orders.paymentMethod.LINKTOPAY': 'PSE, Efectivo y Billeteras Digitales',
  'orders.paymentMethod.CASH': 'Cash',
  'orders.paymentMethod.ZALOPAY': 'Zalo Pay',
  'orders.createOrder': 'Create Order',
  'orders.actualStatus': 'Actual Status',
  'orders.newStatus': 'New Status',
  'orders.results': 'Results',
  'orders.purchaseMadeByWhatsapp': 'Purchase made by the bot from whatsapp',
  'orders.purchaseMadeByWhatsappType1': 'Type of purchase: Home delivery',
  'orders.purchaseMadeByWhatsappType2': 'Type of purchase: Buy and Collect',
  'orders.DontCollectMoney': "Don't collect money",

  'ordenDetail.title': 'Order Items',
  'ordenDetail.ReasonCancellation': 'Cancellation reason',
  'ordenDetail.ReasonReview': 'Audit reason',
  'ordenDetail.transit': 'DRIVER MAP WITH THE ORDER TOWARDS YOUR DESTINATION',

  'ordersItems.title': 'Order Detail',
  'ordersItems.items': 'ORDER ITEMS',
  'ordersItems.message': 'The items were deleted correctly, save the changes to see reflected in the order.',
  'ordersItems.messageError': 'You have no items selected.',
  'ordersItems.confirmDelete': 'Are you sure to delete the items?',
  'ordersItems.toItemsDelete': 'Delete Items',
  'ordersItems.confirm1': 'Are you sure to update the order?',
  'ordersItems.toSaveChanges': 'Only save changes',
  'ordersItems.confirm2': 'Are you sure to update and confirm the order?',
  'ordersItems.toSaveChangesAndDelivery': 'Save changes and mark as delivered',
  'ordersItems.messageinf': 'The quantity cannot be higher than already existed on system.',
  'ordersItems.mensageSuccess': 'The status has been changed.',
  'ordersItems.mensageSuccess2': 'It has been edited.',
  'ordersItems.loadingDateChange': 'Loading the date change.',
  'ordersItems.noChanges': 'It has no changes.',
  'ordersItems.changeDateDelivery': 'Change Delivery Date',
  'ordersItems.GenetatePDF': 'Generating the order PDF',
  'ordersItems.GenetateZIP': 'Generating  purchase order  ZIP file',
  'ordersItems.statusReasonToCancel': 'Store Closed / Customer not contacted',
  'ordersItems.statusReasonToCancel2': 'Order didnt place by store',
  'ordersItems.statusReasonToCancel3': 'Without cash',
  'ordersItems.statusReasonToCancel4': 'Duplicate order',
  'ordersItems.statusReasonToCancel5': 'Quality of products',
  'ordersItems.statusReasonToCancel6': 'Wrong products',
  'ordersItems.statusReasonToCancel7': 'Late delivery',
  'ordersItems.statusReasonToCancel8': 'No Coverage',
  'ordersItems.statusReasonToCancel9': 'Out Of Stock',
  'ordersItems.statusReasonToCancel10': 'Wrong adress',
  'ordersItems.statusReasonToCancel11': 'Test',
  'ordersItems.statusReasonToCancel12': 'Store Owner',
  'ordersItems.confirmCancel': 'Are you sure to cancel the order?',
  'ordersItems.cancelOrden': 'Cancel orden',
  'ordersItems.sendEmailOrden': 'Do you want to resend the order by email?',
  'ordersItems.sendEmailOrder': 'Send by email',
  'ordersItems.downloadOrderPDF': 'Do you want to download order PDF?',
  'ordersItems.downloadOrderZIP': 'Do you want to download the order Zip file?',
  'ordersItems.downloadPDF': 'Download PDF file',
  'ordersItems.downloadZIP': 'Download ZIP file',
  'OrdersItems.changeStatus': 'Loading order status change',
  'OrdersItems.sameState': 'The status is the same ...',
  'ordersItems.changeStatusOrder': 'Change order status',
  'ordersItems.selectState': 'Select status',
  'ordersItems.changeStatusOrderReview': 'Change Checking status',
  'ordersItems.editQuantity': 'Edit Item Quantity',
  'ordersItems.changeQuantity': 'Change Quantity',
  'ordersItems.name': 'Name',
  'ordersItems.pickerModal': 'Select the orders to remove the product',
  'ordersItems.searchOrders': 'Search orders',
  'ordersItems.status': 'Order Status',
  'ordersItems.removeProducts': 'Remove products',
  'ordersItems.mensageError': 'There are no orders with this product or those that exist are in wrong order status',
  'ordersItems.mensageLoading': 'Discounting order products...',
  'ordersItems.DeliveryDateUpdated': 'Delivery date updated',
  'ordersItems.pdf': 'The order PDF has been downloaded.',
  'ordersItems.zip': 'Zipe file has been downloaded',
  'ordersItems.sendEmail': 'Email has been sent successfully.',
  'ordersItems.updateStatus': 'The status has been updated.',
  'ordersItems.inventoryOrders': 'Inventory quantity',
  'ordersItems.messageRevisionDefault': 'Cannot receive it. Delivery notice push',
  'ordersItems.remplaceProductTitle': 'Replace product',
  'ordersItems.remplaceProductDescription': 'Select the product you want to replace to',

  'promotions.promotion': 'Promotions',
  'promotions.title': 'Promotions',
  'promotions.subtitle': 'Quqo promotions infomation',
  'promotions.all': 'All',
  'promotions.acitive': 'Active',
  'promotions.inactive': 'Inactive',
  'promotions.textBox': 'Filter by name',
  'promotions.filter': 'Filter',
  'promotions.newPromotion': 'Create promo',
  'promotions.date': 'Creation date',
  'promotions.UpdateDate': 'Update date',
  'promotions.quantityCurrent': 'Current quantity',
  'promotions.titleForm': 'Promotions Form',
  'promotions.promoCode': 'Promo code',
  'promotions.promoCodeRequired': 'Please enter the promotion code!',
  'promotions.public': 'Public?',
  'promotions.Limit': 'Limited?',
  'promotions.totalQuantity': 'Total Quantity',
  'promotions.totalQuantityRequired': 'Please enter the total quantity in promotion!',
  'promotions.totalUser': 'Quantity per user',
  'promotions.totalUserRequired': 'Please enter the maximum quantity per user!',
  'promotions.productSelect': 'Select product',
  'promotions.productSelectRequired': 'Please select a product!',
  'promotions.normalPrice': 'Regular price',
  'promotions.promotionPrice': 'Promotion price',
  'promotions.imageChange': 'Change image',
  'promotions.textPromotionSave': 'To add the image you have to save the promotion first',
  'promotions.Save': 'Save',
  'promotions.sendNotifications': 'Send push',
  'promotions.sendNotificationsOK': 'Are you sure to send the push?',
  'promotions.alertSend': 'The notification has been sent',
  'promotions.requireName': 'Please Enter the Promotion name!',
  'promotions.savePromo': 'The new promotion has been saved',
  'promotions.updatePromo': 'The promotion has been updated.',
  'promotions.ChangeStatus': 'The status has been changed.',
  'promotions.selectType': 'Select type',
  'promotions.none': 'None',
  'promotions.product': 'Product',
  'promotions.departament': 'Department',
  'promotions.category': 'Category',
  'promotions.combined': 'Combined',
  'promotions.atLeastOne': 'Multiple products Banner',
  'promotions.minAmountListProduct': 'Buy a minimum amount in products included in the list',
  'promotions.createPrmotions': 'Save Promotion',
  'promotions.addProduct': 'Add Product',
  'promotions.addPrize': 'Add Reward',
  'promotions.confirmDelete': 'Are you sure to remove this product?',
  'promotions.confirmNoPrize': 'Are you sure you dont create a Reward?',
  'promotions.noDelete': 'There must be at least one product',
  'promotions.tipePrize': 'Reward Type',
  'promotions.discount': 'Discount',
  'promotions.modalConfirm': 'Are you sure you save this promotion?',
  'promotions.quantity+': 'Max quantity per user',
  'promotions.quantity-': 'Quantity needed',
  'promotions.distributeDiscount': 'You must to distribute the discount on the combo products',
  'promotions.successImage': 'The image was saved',
  'promotions.flashType': 'Flash type',
  'promotions.flashType0': 'No flash promotion',
  'promotions.flashType1': 'By quantity',
  'promotions.flashType2': 'By time',
  'promotions.selectDate': 'Select date and time',
  'promotions.messageErrorDate': 'You must to select the end date and time',
  'promotions.messageConfigProduct': 'You cannot create a promotion combined with products with additional configuration, please select another product to continue',
  'promotions.showIn': 'Show in:',
  'promotions.minimumAmount': 'Minimum amount',
  'promotions.promotion1': 'Promotion',
  'promotions.errorPromotoType5': 'For this type of promotion is not possible to add products with additional configurations.',
  'promotions.maximumSaleQuantity': 'Maximum sales quantity',

  'specialPromotion.buttonText': 'Create special promotion',
  'specialPromotion.listProducts': 'List of products',
  'specialPromotion.selector': 'Promotion type',
  'specialPromotion.type_selector': 'Selector Type',
  'specialPromotion.max_discount_by_user': 'Maximum discount per user',
  'specialPromotion.min_amount': 'Minimum amount',
  'specialPromotion.type_gift': 'Prize type',
  'specialPromotion.gift_value': 'Prize value',
  'specialPromotion.gift_coupons': 'Coupon',
  'specialPromotion.rules': 'Add the rules',
  'specialPromotion.PROMOTION_PRIZE_PRODUCT': 'Product',
  'specialPromotion.PROMOTION_PRIZE_DISCOUNT': 'Discount',
  'specialPromotion.PROMOTION_PRIZE_ROULETTE': 'Roulette',
  'specialPromotion.PROMOTION_PRIZE_COUPON': 'Coupon',
  'specialPromotion.PROMOTION_PRIZE_CASHBACK': 'Cashback',
  'specialPromotion.PROMOTION_PRIZE_PERCENT': 'Percentage',
  'specialPromotion.successCreatePromo': 'The special promotion was created successfully.',

  'products.product': 'Products',
  'products.title': 'PRODUCT LIST',
  'products.subtitle': 'Quqo Products Information',
  'products.textBox': 'Filter by name',
  'products.promo': 'Promo',
  'products.priceCurrent': 'Current price',
  'products.EAN': 'SKU',
  'products.productInPromo': 'This product is in promotion.',
  'products.editProduct': 'Edit Product',
  'products.requiredName': 'Please enter the product name!',
  'products.requiredDescription': 'Please enter the product description!',
  'products.requiredQunatity': 'Please enter the quantity!',
  'products.requiredPrice': 'Please enter the product price!',
  'products.brand': 'Brand',
  'products.categories': 'Categories',
  'products.changeImage': 'Change image',
  'products.addImage': 'Add Imagen',
  'products.requiredDepartament': 'The department is required',
  'products.brandDepartament': 'The brand is required',
  'products.categoryDepartament': 'The category is required',
  'products.featured': 'Spotlight',
  'products.level': 'Level',
  'products.updateLoad': 'Updating product...',
  'products.updateSuccess': 'The product was successfully updated.',
  'Products.confirm': 'Are you sure to update the product?',
  'Products.confirmCancel': 'Are you sure to cancel the edition?',
  'Products.descuento': 'Price with discount',
  'Products.descuentoMessage': 'The discount price cannot be greater than the base price.',
  'Products.max': 'Maximum per user',
  'Products.updatedProduct': 'The product has been updated',
  'Products.createProduct': 'Product has been added to the catalogue',
  'Products.manufactured': 'Manufactured',
  'Products.DownloadCatalog': 'DOWNLOAD CATALOGUE',
  'Products.successChangeStatus': 'The status has been changed.',
  'Products.packaging': 'Packaging',
  'Products.PackagingUnit': 'Packaging Unit',
  'Products.MinimunUnitSell': 'Minimun Unit Sell for inventory. (-1) Unlimited',
  'Products.productsUpdate': 'Update Product',
  'Products.addConfigByq2c': 'This configuration will be added to the Q2C catalogue',
  'Products.toBlock': 'Lock',
  'Products.tax': 'Taxes',
  'Products.taxPercent': 'Tax percentage',
  'Products.amountTax': 'Tax amount',
  'Products.uploadFile': 'Upload File',
  'Products.downloadFile': 'Download File',
  'Products.fileToBill': 'Upload product taxes file',
  'Products.presentation': 'Packaging',
  'products.minQuantity': 'Min quantity per orden. (0) No minimum',
  'products.editPrice': 'Edit price',
  'products.downloadByEmail': 'The catalogue will be sent by email in approximately 3 minutes.',
  'products.scales': 'Scales',
  'products.confirmDelete': 'Are you sure to delete the selected image?',
  'products.confirmDeleteSuccess': 'Image deleted successfully',
  'products.confirmAddSuccess': 'The image was added successfully',

  'product.scales.title': 'Price scales for the product',
  'product.scales.min_quantity': 'Min quantuty',
  'product.scales.confirmSave': 'Are you sure to save the scales?',
  'product.scales.save': 'Save Changes',
  'product.scales.addScale': 'Add scale',
  'product.scales.messageSuccess': 'Scales have been saved successfully.',
  'product.scales.errorModal1': 'This product has price scales. Configurations cannot be added.',
  'product.scales.errorModal2': 'This product has settings. It is not possible to add price scales, to add scales you must turn off all existing configuration groups.',
  'product.scales.deleteSuccess': 'Scale removed successfully',
  'product.scales.percentage': 'Percentage',
  'product.scales.absolute': 'Absoluto',
  'product.scales.finalPrice': 'Final price',
  'product.scales.type1': 'Scales by price',
  'product.scales.type2': 'Scales by percent',
  'product.scales.type3': 'Scale Price',
  'product.createScales': 'Create Scales',
  'product.modifyScales': 'Modify Scales',

  'group.title': 'Group',
  'group.addConfig': 'Add new setting to the product',
  'group.variant': 'Variable',
  'group.addition': 'Addition',
  'group.modifier': 'Modifier',
  'group.minConfiguration': 'Minimum settings',
  'group.maxConfiguration': 'Maximum settings',
  'group.Required': 'Required',
  'group.createGroup': 'Create Group',
  'group.updateGroup': 'Update Group',
  'group.newGroup': 'Add New Group',
  'group.config': 'Product settings',
  'group.extraPrice': 'Extra price',
  'group.is_default': 'It is default',
  'group.baseProduct': 'Base product',
  'group.extraPriceType': 'Extra price type',
  'group.changePrice': 'Change price to',
  'group.messageSuccess': 'Group settings have been updated',
  'group.messageDelete': 'Settings have been removed',
  'group.messageSuccessGroup': 'The group has been created',
  'group.messageUpdateGroup': 'The group has been updated',
  'group.questionCreateGroup': 'Are you sure to create the group?',
  'group.questionUpdateGroup': 'Are you sure to update the group?',
  'group.deleteGroup': 'Delete Group',
  'group.deleteGroupConfirm': 'Are you sure to delete this group?',
  'group.noDeleteGroup': 'You must to remove all settings previously',
  'group.youHave': 'You have',
  'group.specialPrice': 'Special price',
  'group.messageProductBase': 'Must to have a base product configuration',

  'ProductList.Title': 'CATALOGUE',
  'ProductList.TitleList': 'Catalog List',
  'ProductList.Confirm': 'Are you sure you update the product?',
  'ProductList.OK': 'The product has been updated.',
  'ProductList.form': 'Products Form',

  'catalogUpdate.cataUpdate': 'CATALOGUE UPDATE',
  'catalogUpdate.cataUpdate1': 'Catalogue Update',
  'catalogUpdate.cataUpdate2': 'Q2C Catalogue',
  'catalogUpdate.title': 'PROVIDERS',
  'catalogUpdate.subtitle': 'Select the provider which you want to update the catalogue',
  'catalogUpdate.subtitleQ2C': 'Update and download the catalogue to show in the Q2C app',
  'catalogUpdate.viewModel': 'See model file',
  'catalogUpdate.productsTotal': 'Total products',
  'catalogUpdate.boxUpdateCatalog2': 'Check if the catalogue to be updated is correct',
  'catalogUpdate.dragFile': 'Click or drag the file to this area to upload',
  'catalogUpdate.boxDragFile': 'Are you sure to update the catalogue?',
  'catalogUpdate.boxDragFile2': 'The total products quantity in the CSV file is',
  'catalogUpdate.boxDragFileLoading': 'The catalogue is loading ...',
  'catalogUpdate.boxDragFileLoading2': 'Remember that this function takes between 5-15 minutes',
  'catalogUpdate.boxDragFileLoading3': 'OK',
  'catalogUpdate.text': 'Remember that this function takes between 5-15 minutes',
  'catalogUpdate.fileSuccess': 'File uploaded successfully',
  'catalogUpdate.fileError': 'File upload failed',
  'catalogUpdate.productsTo': 'Quqo products',

  'users.infoStores': 'Stores Information',
  'users.user': 'Users',
  'users.title': 'USERS',
  'users.subtitle': 'Quqo users Information',
  'users.listUsers': 'Users List',
  'users.whitelist': 'Users Whitelist',
  'users.search': 'Search',
  'users.role.store': 'Store',
  'users.role.salesman': 'Salesforce',
  'users.role.pilot': 'Pilot',
  'users.role.admin_warehouse': 'Admin Warehouse',
  'users.role.warehouse': 'Warehouse',
  'users.role.custom_role': 'Custom Role',
  'users.status.infoStatus1': 'Inactive',
  'users.status.infoStatus2': 'Active',
  'users.status.infoStatus3': 'Invalid',
  'users.status.infoStatus4': 'Blocked',
  'users.status.infoStatus1s': 'Inactives',
  'users.status.infoStatus2s': 'Actives',
  'users.status.infoStatus3s': 'Invalids',
  'users.status.infoStatus4s': 'Blockeds',
  'users.changeCode1': 'Code to activate Stores',
  'users.changeCode2': 'Create a referral code',
  'users.code': 'Create a referral code',
  'users.actualCode': 'Current Code',
  'users.changeCodeComercialTextBox': 'Enter the Code',
  'users.referralCode': 'Change salesman code',
  'users.comercialCode': 'Change activation code',
  'users.noComercial': 'Does not have',
  'users.noStore': 'It is not a store',
  'users.infoCancel': 'Are you sure to cancel?',
  'users.IDComercial': 'Commercial ID',
  'users.restorePassword': 'Reset Password',
  'users.inSendEmail': 'Are you sure to send email to the customer?',
  'users.sendEmail': 'Send email',
  'users.infoUsers': 'User information',
  'users.changeCodeComercial': 'Enter the Salesman code',
  'users.changeCodeSalesman': 'Change Referral Code',
  'users.updaterol': 'User role changed successfully.',
  'users.updateStatus': 'The user status  was changed.',
  'users.updateCode': 'User referral code was changed',
  'users.referralUser': 'The referral code was changed',
  'users.sendEmail2': 'The email has been sent successfully.',
  'users.updateltlg': 'The coordinates have been updated',
  'users.updateUsers': 'User has been updated',
  'users.okSave': 'Are you sure to update the user?',
  'users.uploadFileExito': 'Cargar usuarios de misurtii',
  'users.confirmTextUploadFile': 'Seguro de cargar el archivo XLSX?',
  'users.uploadFile': 'Subir archivo .xlsx',

  'user.address_references': 'Address references',
  'user.editUsers': 'User edition Form',
  'user.country_code': 'Country Code',
  'user.doesNotHave': 'Does not have',
  'user.doesMotApply': 'Does not apply',
  'user.userValidation': 'User validation',
  'user.detailStore': 'Store Detail',
  'user.company': 'Business',

  'users.changeToStore': 'Switch to store',
  'users.nameStore': 'Store Name',
  'users.documentType': 'ID type',
  'users.details': 'Details',
  'users.general': 'General',
  'users.changeLocation': 'Change location',
  'users.successCreateStore': 'Switched to Store',
  'users.questionCreateStore': 'Are you sure to change the user to Store role?',
  'users.updatePermissions': 'Modify Admin permissions ',
  'users.updatePermissionsqrew': 'Modify Qrew permissions',
  'users.changePermissionsText': 'Are you Sure to change permissions for',
  'users.changePermissionsSuccess': 'User permissions have been updated successfully',
  'users.qrew.PROSPECTS_SECTION': 'Potential Stores',
  'users.qrew.DELIVERY_ORDERS_SECTION': 'Delivery Orders',
  'users.qrew.NEAR_STORES_SECTION': 'Closer stores',
  'users.qrew.CONCILIATE_ORDERS_SECTION': 'Conciliated orders',
  'users.qrew.VISIT_SECTION': 'Salesman visits',
  'users.qrew.SUPPORT_SECTION': 'Helpdesk',
  'users.qrew.PICKER_SECTION': 'Picker',
  'users.qrew.WAREHOUSE_INVENTORY_SECTION': 'Inventory',
  'users.discontinued': 'Discontinued',
  'users.discontinuedDate1': 'Discontinued since:',
  'users.discontinuedDate2': 'Discontinued to: ',
  'users.mobilePhone2': 'Additional contact number',
  'users.country_code2': 'Additional contact Country code',
  'users.contactNumberError': 'The contact number cannot be the same than registered already on system',
  'users.addUserWhiteList': 'Add user to the list',
  'users.searchUser': 'Search user',
  'users.alertToWhiteListUser': 'Confirm',
  'users.untilDate1': 'Start date',
  'users.untilDate': 'End date',
  'users.okTextModal': 'Send to the list',
  'users.successWhiteList': 'The user has been added to the list.',
  'users.successUpdateWhiteList': 'The user has been updated.',
  'users.successDeleteWhiteList': 'The user has been removed from the list.',
  'users.QuestionDeleteWhiteList': 'Are you sure to remove this user from the list?',
  'users.currentDate': 'Current date',
  'users.changeDate': 'Change date',
  'users.location': 'Location',
  'users.totalOrdersCancels': 'Canceled orders',
  'users.totalOrdersDelivered': 'Delivered orders',
  'users.returnOfProducts': 'Product Returns',
  'users.totalOrders': 'Total orders',
  'users.userType': 'Type of user',
  'users.statusUser': 'User status',
  'users.suspendedClient': 'Suspended customer',
  'users.activate': 'Activate',
  'users.coordinator': 'Coordinator List',
  'users.coordinatorCreate': 'Create Coordinator role',
  'users.coordinatorCreateSuccesss': 'The new coordinator has been created',
  'users.inheritedProvidersUsers': 'Create new user to provider Child',
  'users.inheritedProvidersUsersCreate': 'Create user',
  'users.usersWithProviderCoverage': 'Users with provider coverage',

  'trackingComercial.tracking': 'Tracking',
  'trackingComercial.searchComercial': 'Search Salesman',

  'activations.CommercialActivations': 'Salesman Activations',
  'activations.search': 'Search Salesman',
  'activations.noUsers': 'No users',
  'activations.noName': 'No Name',
  'activations.NoData': 'No data',

  'Missions.mission': 'Missions',
  'Missions.mission1': 'Mission',
  'Missions.missionDashboard': 'Dashboard',
  'Missions.poitnsEarned': 'Earned points',
  'Missions.poitnsUsed': 'Used Points',
  'Missions.bottom': 'Bottom 10',
  'Missions.top': 'Top 10',
  'Missions.position': 'Position',
  'Missions.effectiveness': 'Effectiveness',
  'Missions.misSubTittle': 'Missions Information',
  'Missions.misSearch': 'Filter by type',
  'Missions.misSelectTipe': 'Select type',
  'Missions.misSelectTipe1': 'Poll',
  'Missions.misSelectTipe2': 'Form',
  'Missions.misSelectTipe3': 'Questionnaire',
  'Missions.misSelectTipe4': 'Location photo',
  'Missions.misFilterRangeDate': 'Filter by date range',
  'Missions.misFilterRangeDate0': 'Last 7 Days',
  'Missions.misFilterRangeDate1': 'Last 8 Days',
  'Missions.misFilterRangeDate2': 'Last 30 Days',
  'Missions.misFilterRangeDate3': 'Last 60 Days',
  'Missions.misFilterRangeDate4': 'Last 90 Days',
  'Missions.misFilterRangeDate5': 'Last 180 Days',
  'Missions.misFilterRangeDate6': 'Last year',
  'Missions.misFilterRangeDate7': 'Current month',
  'Missions.misFilterRangeDate8': 'Current year',
  'Missions.misFilterRangeDate9': 'Personalized',
  'Missions.misFilterBudget': 'Budget',
  'Missions.misFilterEarnedPoints': 'Earned points',
  'Missions.misFilterBudgetThat': 'Remaining Budget',
  'Missions.misFilterDate': 'Expiration date',
  'Missions.misFilterUsers': '# Users Response Quantity',
  'Missions.FilterAll': 'Filter by start and end data',
  'Missions.misCreateMiss': 'Create a new Mission',
  'Missions.nameRequired': 'Please Enter a Mission name!',
  'Missions.typeRequired': 'Please Select a Type!',
  'Missions.zoneRequired': 'Please Select a zone!',
  'Missions.misZones': 'Zone',
  'Missions.misValidation3': 'Please enter a description!',
  'Missions.misDateExpiration': 'Expiration Date',
  'Missions.dateRequired': 'Please Enter the Expiration Date!',
  'Missions.selectDate': 'Select date',
  'Missions.misBudget': 'Budget',
  'Missions.misPoints': 'With points?',
  'Missions.typePrize': 'Reward Type',
  'Missions.misPointsResponse': 'Point earned per response',
  'Missions.excerpt': 'Resume',
  'Missions.publish': 'Send Now',
  'Missions.error1': 'You must to fill all fields',
  'Missions.error2': 'Some of the answer options are empty',
  'Missions.success': 'The mission has been posted',
  'Missions.modal': 'Are you sure to send the mission?',
  'Missions.deleteOption': 'Are you sure to delete this option?',
  'Missions.required2Options': 'There must be at least two types of response',
  'Missions.selectCorrect': 'You must to select a correct answer.',
  'Missions.saveItem': 'The item has been saved.',
  'Missions.errorSave': 'You must fill all fields.',
  'Missions.newOptions': 'New option',
  'Missions.saveOptions': 'Save',
  'Missions.responseText': 'Text response',
  'Missions.responseNumber': 'Numerical response',
  'Missions.fileToMision': 'Upload file for the mission',
  'Missions.insertText': 'Enter text',
  'Missions.typeContent': 'Content type',
  'Missions.type1': 'Photo',
  'Missions.type2': 'Text',
  'Missions.type3': 'File',
  'Missions.type4': 'MP4',
  'Missions.question': 'Question',
  'Missions.optionsAll': 'Photo, Text, File, text...',
  'Missions.tipeOfResponse': 'Response type',
  'Missions.tipeOfResponse1': 'Multiple Option',
  'Missions.tipeOfResponse2': 'Unique Option',
  'Missions.tipeOfResponse3': 'Text response',
  'Missions.tipeOfResponse4': 'Numerical Response',
  'Missions.insertQuestion': 'Enter a question',
  'Missions.confirmDelete': 'Do you want to delete the question?',
  'Missions.newQuestion': 'New question',
  'Missions.BudgetRemaining': 'Remaining Budget',
  'Missions.TotalUsers': 'Total Users',
  'Missions.listStores': 'Stores list that have completed the Mission',
  'Missions.ResendMission': 'Resend Mission',
  'Missions.resendConfirm': 'Are you sure to resend the mission?',
  'Missions.sendOK': 'The mission has been resent successfully',
  'Missions.sendError': 'There was an error resending the survey, try again later.',
  'Missions.icon': 'Icon',
  'Missions.filterByProvider': 'Filter by provider',
  'Missions.responses': 'Responses',
  'Missions.confirmDownload': 'Download answer file',
  'Missions.buttonDownload': 'Download Answers on XLSX',
  'Missions.subQuestions': 'Create sub question',
  'Missions.listSubQuestions': 'Sub questions list',
  'Missions.deleteSubQuestions': 'Delete sub questions',
  'Missions.deleteSubQuestionsModal': 'Are you sure to delete the sub questions group?',
  'Missions.addSubQuestion': 'New Sub question',
  'Missions.quantityLimitUser': 'Response limit',
  'Missions.available': 'Mission available',
  'Missions.prize0': 'None',
  'Missions.prize1': 'Qoins',
  'Missions.prize2': 'Objects',
  'Missions.namePrize': 'Reward name',
  'Missions.listOfQuestions': 'Question List',
  'Missions.ListOfAwards': 'Reward List',
  'Missions.quantityInInventory': 'Quantity in Stock',
  'Missions.languageES': 'in Spanish 🇪🇸',
  'Missions.languageEN': 'in English 🇺🇸',
  'Missions.languageVI': 'in Vietnamese 🇻🇳',
  'Missions.errorNoLanguage': 'There must be at least one language to create the mission',
  'Missions.errorNoLanguageProvider': 'You must have at least the primary language for selected provider.',

  'Notifications.title': 'Notifications',
  'Notifications.ListNotifications': 'Notification list',
  'Notifications.newNotification': 'Create new notification',
  'Notifications.simple': 'Simple',
  'Notifications.advert': 'Announcement',
  'Notifications.product': 'Product',
  'Notifications.url': 'URL',
  'Notifications.titleTable': 'Title',
  'Notifications.accion': 'Action',
  'Notifications.newNotifications': 'Create Notification',
  'Notifications.requireName': 'Please enter the notification name!',
  'Notifications.requireDesc': 'Please enter the notification description!',
  'Notifications.public': 'The notification has been sended',
  'Notifications.advertImage': 'Image:',
  'Notifications.requideImage': 'Please upload a picture!',
  'Notifications.advertTextImage2': 'Update brand image',
  'Notifications.simpleSelections': 'Select:',
  'Notifications.requireZone': 'Please Select the zone!',
  'Notifications.documentUser': 'Enter the users email:',
  'Notifications.simpleSendForall': 'All',
  'Notifications.storesTo': 'All stores',
  'Notifications.requireImage': 'Please enter the users ID!',
  'Notifications.simpleSendFor': 'Send for',
  'Notifications.requireFor': 'Select to which users it is directed!',
  'Notifications.simpleSendForZones': 'Zones',
  'Notifications.simpleSendForUsers': 'Users  - Email',
  'Notifications.simpleSendForUsersPhone': 'Users  - Phone Number',
  'Notifications.simpleSendForFile': 'CSV file',
  'Notifications.deepLink': 'Deep Link:',
  'Notifications.productEan': 'SKU',
  'Notifications.requireEan': 'Please enter the product SKU!',
  'Notifications.uploadFile': 'Add a CSV file with email list for sending push notifications',
  'Notifications.errorEmails': 'This is not a valid email:',
  'Notifications.list': 'See Email list',
  'Notifications.listModal': 'Email List',
  'Notifications.listInput': '(separate the emails by (,) and without spaces)',
  'Notifications.listInputPhones': '(separate the phones by (,) and without spaces)',
  'Notifications.confirm': 'Are you sure to send the notification?',
  'Notifications.analysis': 'Analysis',
  'Notifications.totalDelivered': 'Total Delivered',
  'Notifications.totalOpened': 'Total Opened',
  'Notifications.PostNow': 'Send Now',
  'Notifications.scheduleNotification': 'Schedule Notification',
  'Notifications.selectTime': 'Select the Time',
  'Notifications.double': 'Duplicate',
  'Notifications.banner': 'Banner',
  'Notifications.publishIn': 'Post in:',
  'Notifications.schedule': 'Scheduled',
  'Notifications.Directly': 'Directly',
  'Notifications.publishType': 'Posting type',
  'Notifications.combined': 'Combined Products',
  'Notifications.allUsersNoCompleted': 'Only for users who have not completed the mission',
  'Notifications.allProviders': 'All Providers associated with mision',
  'Notifications.smsButton': 'New SMS',
  'Notifications.message': 'Message',
  'Notifications.AllTheCompanies': 'All companies of',
  'Notifications.SelectCompanies': 'Selected companies',
  'Notifications.SelectedCompanies': 'Select companies',
  'Notifications.SendTo': 'Send to',
  'Notifications.seeCompanies': 'See companies',
  'notifications.sound': 'Notification Sound',
  'notifications.soundDefault': 'Default sound',
  'notifications.soundQuqo': 'Quqo Sound',
  'notifications.link': 'Link',
  'notifications.mission': 'Mission',
  'notifications.appSections': 'APP sections',

  'campaigns.title': 'Campaigns',
  'campaigns.subTitle': 'Campaigns list',
  'campaigns.new': 'New Campaign',
  'campaigns.new1': 'New Campaign',
  'campaigns.appType': 'App module',
  'campaigns.selectChannel': 'Select a channel',
  'campaigns.createCampaign': 'Are you sure to create this campaign?',
  'campaigns.createLoading': 'Creating campaign...',
  'campaigns.announce': 'Announcement',
  'campaigns.promotion': 'Promotions',
  'campaigns.promotion&ids': 'Promotion Detail',
  'campaigns.department&ids': 'Department',
  'campaigns.category&ids': 'Category',
  'campaigns.brand&ids': 'Brand',
  'campaigns.search': 'Search',
  'campaigns.scan': 'Barcode',
  'campaigns.product&ids': 'Product Detail',
  'campaigns.combined_product&ids': 'Combined Products',
  'campaigns.mission': 'Missions',
  'campaigns.mission&ids': 'Mission Detail',
  'campaigns.qoin': 'My Qoins',
  'campaigns.reward': 'My Rewards',
  'campaigns.qpay': 'Qpay',
  'campaigns.support': 'Helpdesk',
  'campaigns.order': 'Orders',
  'campaigns.order&ids': 'Order detail',
  'campaigns.review': 'Rate us',
  'campaigns.account': 'My account',
  'campaigns.channel': 'Channel',

  'Inventory.ListInventory': 'Inventory list',
  'Inventory.tableInventory': 'Inventory table',
  'Inventory.name': 'Product Name',
  'Inventory.title': 'Inventory',
  'Inventory.select4': 'Bill',
  'Inventory.select5': 'Retailer',
  'Inventory.addProduct': 'Add Product',
  'Inventory.subtract': 'Subtract Stock to Product',
  'Inventory.addStock': 'Add Stock to Product',
  'Inventory.messageloading': 'Loading product inventory',
  'Inventory.messageloading2': 'Adding product to inventory ..',
  'Inventory.messageloading3': 'Subtracting product from inventory ..',
  'Inventory.selectRetailer': 'Select the retailer.',
  'Inventory.productId': 'Product id',
  'Inventory.requirePrice': 'Please enter the product price!',
  'Inventory.addProductBuy': 'Purchase',
  'Inventory.selectProduct': 'Select a product!',
  'Inventory.addProducts': 'Product',
  'Inventory.uploadFileInventory': 'Inventory',
  'Inventory.uploadFileCSV': 'Add a new inventory using a XLSX file.',
  'Inventory.upFile': 'Upload Inventory .xlsx',
  'Inventory.validation': 'Are you sure to upload a new inventory?',
  'Inventory.validationConfirm': 'Are you sure to upload the file?',
  'Inventory.uploadFile': 'Upload inventory file',
  'Inventory.downloadFile': 'Download inventory file',
  'Inventory.missingProduct': 'Orders with missing products',
  'Inventory.missingProduct2': 'Missing products',
  'Inventory.orderDeleteReasons': 'Out of Stock',
  'Inventory.orderDeleteReasons2': 'Discontinued',
  'Inventory.popConfirm': 'Are you sure to delete this product?',
  'Inventory.orderSlopePicker': 'Pending orders by picker',
  'Inventory.orderID': 'Order ID',
  'Inventory.download': 'Export Orders',
  'Inventory.message': 'Loading pending orders by picker',
  'Inventory.noProducts': 'No pending products, you can now pass the order to ready to complete.',
  'Inventory.changeStatus': 'Changing the order status',
  'Inventory.download2': 'Downloading the missing products list in inventory.',
  'Inventory.orderSlopeName': 'Picker name',
  'Inventory.orderSlopePrice': 'Price',
  'Inventory.orderSlopeTotal': 'Total Items',
  'Inventory.orderSlopeDate': 'Delivery date',
  'Inventory.orderSlopeDateCreate': 'Creation date',
  'Inventory.orderSlopeComment': 'Comment',
  'Inventory.orderSlopeMark': 'Mark as ready',
  'Inventory.returnOrders': 'Orders with return products',
  'Inventory.orderSlopeReturnDownload': 'Loading orders with products to return',
  'Inventory.returnRecord': 'Registering Return',
  'Inventory.forInventory': 'To inventory',
  'Inventory.DamagedProducts': 'Damaged Products',
  'Inventory.ProductsNotReceived': 'Products Not Received',
  'Inventory.confirmText': 'You are receiving these products. Confirm',
  'Inventory.Return': 'Return',
  'Inventory.ReturnTableDetail': 'Return Detail',
  'Inventory.ReturnDetail': 'Return Detail',
  'Inventory.CommittedAmount': 'Committed Amount',
  'Inventory.ToInventory': 'To Inventory',
  'Inventory.DamagedProduct': 'Damaged product',
  'Inventory.DeliveredtoShopkeeper': 'Delivered to Store Owner',
  'Inventory.OriginalQuantity': 'Original Quantity',
  'Inventory.Amounttobereturned': 'Amount to be returned',
  'Inventory.noDevolutions': 'This order has not had returns',
  'Inventory.ActualQuantity': 'Remaining Quantity',
  'Inventory.inicialQuantity': 'Initial Quantity',
  'Inventory.productMissing': 'Orders with missing product',
  'Inventory.earringsPicker': 'Pending orders by picker',
  'Inventory.ReturnOrdersTitle': 'Orders to return',
  'Inventory.Message': 'Loading pending orders by picker',
  'Inventory.update': 'The inventory has been updated.',
  'Inventory.cancel': 'The product has been canceled .',
  'Inventory.last30days': 'Last 30 Days',
  'Inventory.DEVOLUCION_PROVEEDOR': 'PROVIDER RETURNS',
  'Inventory.AVERIA': 'DAMAGED PRODUCTS',
  'Inventory.VENCIDO': 'EXPIRED',
  'Inventory.VENTA_INTERNA': 'INTERNAL SALE',
  'Inventory.CONSUMO_INTERNO': 'INTERNAL CONSUMPTION',
  'Inventory.INGRESO_ERRÓNEO': 'WRONG ENTRY',
  'Inventory.quantityInOrders': 'Quantity in orders',
  'Inventory.virtualQuantity': 'Virtual quantity',
  'Inventory.virtualQuantityFilter1': 'CRITICAL STATUS',
  'Inventory.virtualQuantityFilter2': 'NEW CHANGES',
  'Inventory.virtualQuantityFilter3': 'NO CHANGES',
  'Inventory.nit': 'License ID: ',
  'Inventory.lastMovement': 'Last movement',
  'Inventory.uploadInventoryDesc': 'This load works only as a subtraction and addition in the inventory of the products loaded in the excel, important to remember. There will be transactions corresponding to said addition and subtraction',

  'Brands.Brand': 'Brands',
  'Brands.Brand1': 'Brand',
  'Brands.tittle': 'Brand list',
  'Brands.search': 'Search name',
  'Brands.createBrands': 'Add brand',
  'Brands.notImage': 'This brand has no image',
  'Brands.createBrand': 'Creating the brand',
  'Brands.uploadBrand': 'Updating the brand',
  'Brands.createBrandsId': 'Brand ID',
  'Brands.createBrandUpdate': 'Update Name',
  'Brands.createBrandImage': 'Image',
  'Brands.createBrandImageRequired': 'Please enter the product price!',
  'Brands.createBrandUploadImage2': 'Update the brand image',
  'Brands.createBrandNameRequired': 'Please enter the brand name!',
  'Brands.BrandImage': 'Brand Image',
  'Brands.uploadFileBrands': 'Upload brands',
  'Brands.uploadFileSubtitle': 'Add a new brand CSV file',
  'Brands.createBrandsUpload': 'Are you sure to upload a new brand XLSX file?',
  'Brands.finish': 'The file has been loaded.',

  'Zones.zone': 'Zones',
  'Zones.list': 'List',
  'Zones.Maps': 'Map',
  'Zones.searchName': 'Search by name',
  'Zones.search': 'Search',
  'Zones.Sincronizar': 'Synchronize stores',
  'Zones.addZone': 'Add zone',
  'Zones.Sincronizar1': 'Are you sure to synchronize stores areas of',
  'Zones.Sincronizar2': 'Synchronizing stores with zones...',
  'Zones.selectZonesStores': 'Select the zones to show the stores:',
  'Zones.selectZone': 'Select zone',
  'Zones.country': 'Country',
  'Zones.change': 'You have changed the coordinates!',
  'Zones.question': 'Do you want to cancel or update?',
  'Zones.RequiredName': 'Please enter the area name!',
  'Zones.RequiredLocalidad': 'Please select the zone!',
  'Zones.form': 'Zones Form',
  'Zones.createZone': 'The area has been created.',
  'Zones.Update': 'The area has been edited.',
  'Zones.listStores': 'Stores list',
  'Zones.editConfigZone': 'Edit zone settings',

  'holidays.holidays': 'Holidays',
  'holidays.listHolidays': 'Holiday List',
  'holidays.addHolidays': 'Add holiday',
  'holidays.new': 'New holiday',
  'holidays.edit': 'Edit holiday',
  'holidays.requiredDate': 'The date is required.',
  'holidays.alertSuccess': 'You have created a new Holiday register',
  'holidays.updateSuccess': 'The holiday date has been updated',
  'holidays.create': 'Creating a new holiday.',
  'holidays.update': 'Updating the date...',
  'holidays.confirmDelete': 'Are you sure to delete this holiday?',
  'holidays.actual': 'Current:',
  'holidays.alertdelete': 'The holiday was successfully removed',
  'holidays.dayHoliday': 'Holiday',

  'providers.title': 'Providers',
  'providers.list': 'Provider List',
  'providers.create': 'Provider form',
  'providers.daylyCut': 'Cut-off time',
  'providers.image': 'Provider image',
  'providers.minAmount': 'Minimum purchase',
  'providers.pointValue': 'Gift points',
  'providers.laborableDays': 'Business Days',
  'providers.laborableDeliveryDays': 'Laborable Delivery Days',
  'providers.appReference': 'App Reference',
  'providers.currency': 'Currency',
  'providers.initialBonus': 'Initial bonus in points',
  'providers.pointsValue': 'Value per point',
  'providers.deliveryDays': 'Delivery day',
  'providers.delivery_information': 'Delivery information',
  'providers.billing_information': 'Billing information',
  'providers.language': 'Language',
  'providers.emailPDF': 'Emails',
  'providers.confirm': 'Are you sure to send the data of this provider?',
  'providers.earningRate': 'Multiplier',
  'providers.minPoints': 'Minimum points for purchase',
  'providers.loading': 'Creating provider ...',
  'providers.loadingUpdate': 'Updating provider...',
  'providers.successAlert': 'A new provider was added',
  'providers.successEditAlert': 'Provider was updated',
  'providers.buttonSend': 'Send',
  'providers.max_slots_calendar': 'Number of calendar days',
  'providers.type_of_shipping_cost': 'Type of shipping cost',
  'providers.default_shipping_cost': 'Default shipping cost',
  'providers.big_amount': 'Order Alert Amount',
  'providers.daily_cut': 'Cut-off time',
  'providers.max_orders_day': 'Maximum number of orders per day',
  'providers.max_delivery_days': 'Maximum delivery days',
  'providers.parent_provider': 'Parent provider',
  'providers.min_boxes': 'Minimum boxes',
  'providers.catalog_boxes': 'Minimum boxes?',
  'providers.timezone': 'Timezone',
  'providers.inheritance_distance': 'Deliveries coverage in meters',
  'providers.type_coverage': 'Select the type of coverage',
  'providers.delivery_push_notification': 'Notify user 1 day before delivery',
  'providers.poits_form': 'Points',
  'providers.polygono': 'Polygon',
  'providers.show_powered_by': 'Show powered by',
  'providers.block_assign_pilot_order_before_date': 'Block in assignment to pilots',
  'providers.help': 'Provider name',
  'providers.help1': 'Select the provider country',
  'providers.help2': 'If it is a Child provider you must select its parent provider.',
  'providers.help3': 'Select the providers region time zone',
  'providers.help4': 'Enter the country currency type example: VND',
  'providers.help5': 'Enter the minimum purchase Amount ($)',
  'providers.help6': 'Enter the initial bonus in points for new users',
  'providers.help7': 'Enter the point cost per unit',
  'providers.help8': 'Enter the value of the multiplier from points to money',
  'providers.help9': 'Enter the minimum points value per purchase',
  'providers.help10': 'Select main app language for users',
  'providers.help11': 'Enter the emails for sending orders PDF file separated by coma (,) example: admin @ quqo.com, admin2 @ quqo.com',
  'providers.help12': 'Number of days available in the app calendar to place orders',
  'providers.help13': 'Enter the order shipping cost when it does not complete the minimum purchase for free shipping',
  'providers.help14': 'Enter the maximum order amount to alert about checking',
  'providers.help15': 'Maximum orders per day',
  'providers.help16': 'Enter the available days for delivery',
  'providers.help17': 'Does the provider sell by the minimum number of boxes?',
  'providers.help18': 'If the provider sells by number of boxes, how many boxes is the minimum?',
  'providers.help19': 'Enter maximum days on calendar to respect delivery service',
  'providers.help20': 'Enter Delivery days separated by commas (,) 0,1,2,3,4,5,6 where: Monday: 1, Tuesday: 2 ... Sunday: 0',
  'providers.help21': 'Enter the provider cutoff time using 24 hrs format',
  'providers.help22': 'Provider status (On or off)',
  'providers.help23': 'Enter the delivery range around in meters. Example: 30',
  'providers.help24': 'Check active if you want to notify the customer one day before with a push notification that their order will be sent',
  'providers.help25': 'Enables / disables the use of points in the provider',
  'providers.help26': 'Select the zones you want to associate with your city',
  'providers.help27': 'Select the type of coverage you want to have (locations - range in meters)',
  'providers.help28': 'Select the polygon for provider coverage.',
  'providers.help29': 'Enter the minimum required for repurchases',
  'providers.help30': 'Show Provider name on products.',
  'providers.help31': 'Block order assignments to pilots before the delivery date',
  'providers.help32': 'Select the type of shipping cost: Flat= The same price for any order. Percentage= Total percentage of the order',
  'providers.help33': 'Enter the working delivery days for this supplier',
  'providers.help34': 'Ingresa el nombre de la aplicación en la que estara disponible el proveedor',
  'providers.polygonUpdateConfirm': 'Are you sure to update the coverage based on the polygon for this provider?',
  'providers.selectPolygon': 'Select Polygon',
  'providers.minAmountRepurchase': 'Minimum required for the repurchase',
  'providers.flat': 'Flat',
  'providers.percentage': 'Percentage',

  'retailers.title': 'Retailers',
  'retailers.list': 'Retailer List',
  'retailers.create': 'Retailer Form',
  'retailers.isWholesaler': 'Is a Big Wholesaler',
  'retailers.confirm': 'Are you sure to send the retailerdetails?',
  'retailers.successAlert': 'Retailers have been updated',
  'retailers.save': 'Save',

  'proccess.title': 'Picking process',
  'proccess.subtitle': 'Picking Process List',
  'proccess.send1': 'Are you sure you create the picking process?',
  'proccess.send2': 'Delivery date: ',
  'proccess.error': 'You must select a date to continue',
  'proccess.error2': 'Make sure there is no active process.',
  'proccess.success': 'The process has been created',
  'proccess.selectDate': 'Picking Process for orders with delivery date:',
  'proccess.routeOptimization': 'Route Optimization',
  'proccess.ListRoute': 'Routes',
  'proccess.route': 'Route',
  'proccess.procces': 'Process',
  'proccess.rute': 'Delivery guy Route',
  'proccess.stimatedTime': 'Estimated Time',

  'inventoryprocess.title': 'Inventory Process',
  'inventoryprocess.subtitle': 'Inventory Process List',
  'inventoryprocess.sure': 'A new inventory process will be created for the current day',
  'inventoryprocess.turnOffMessage': 'Are you sure you want to turn OFF the process?',
  'inventoryprocess.turnOnMessage': 'Are you sure you want to turn ON the process?',
  'inventoryprocess.restartMessage': 'Are you sure you want to RESTART the process?',
  'inventoryprocess.turnOffMessageOK': 'Process has been closed',
  'inventoryprocess.restartMessageOK': 'Process has been restarted',
  'inventoryprocess.printMessage': 'Are you sure you want to print the order?',
  'inventoryprocess.printMassive': 'Print All Orders',

  'picker.available': 'Not assigned',
  'picker.pending': 'Pending',
  'picker.pending1': 'Pendings',
  'picker.inProcess': 'In Process',
  'picker.Finished': 'Finished',
  'picker.Finished1': 'Finished',
  'picker.Print': 'Print',
  'picker.Forwarded': 'Process Forwarded to Picker',
  'picker.Restart': 'Restart Process',
  'picker.changeStatusMessage': 'Are you sure you want to change the status?',
  'picker.NoEdit': 'The order cannot be edited',
  'picker.OverdueOrders': 'Overdue Orders',
  'picker.Ranking': 'Ranking',
  'picker.viewListRoutes': 'Routes',
  'picker.viewListOrders': 'Orders Detail',
  'picker.position': 'Position',
  'picker.duration': 'Duration',
  'picker.distance': 'Distance',
  'picker.vehicle': 'Vehicle',
  'picker.detailMarker': 'Stop. Click on the marker to see more details of the delivery ',
  'picker.freeDate': 'Free date',

  'supplies.supplies': 'Supplies',
  'supplies.title': 'Supply List',
  'supplies.addSupplies': 'Add',
  'supplies.formSupplies': 'Supplies Form',
  'supplies.buttonADD': 'Are you sure you send the data of this supply?',
  'supplies.createLoading': 'Creating supply...',
  'supplies.updateLoading': 'Updating supply...',
  'supplies.deleteModal': 'Are you sure to delete the supply?',
  'supplies.delete': 'Remove',

  'localities.title': 'Locations',
  'localities.titleList': 'Locations list',
  'localities.display_order': 'Display order',
  'localities.intermediate_days': 'Intermediate days',
  'localities.delivery_days': 'Delivery days',
  'localities.editModal': 'Edit location',
  'localities.createModal': 'Create locaton',
  'localities.addModal': 'Add localidad',
  'localities.createLoading': 'Creating location...',
  'localities.EditandoLoading': 'Editing location...',
  'localities.alertSuccess': 'The new location has been created successfully',
  'localities.alertSuccessEdit': 'The location has been updated',
  'localities.confirmText': 'Are you sure you want to remove this location?',
  'localities.alertdelete': 'The localition has been removed',
  'localities.min_order_amount': 'Minimum order amount',
  'localities.min_order_repurchase_amount': 'Minimum order amount for repurchase',
  'localities.min_price_for_free_shipping': 'Minimum amount for free shipping',
  'localities.first_order_min_price_for_free_shipping': 'Minimum amount for free shipping 1st order',
  'localities.shipping_cost': 'Shipping cost',
  'localities.customer_shipping_cost': 'Customer shipping cost',
  'localities.shipping_cost_percent': 'Percentage of total order',
  'localities.customer_min_amount': 'Customer min amount',
  'localities.qtc_active': 'Qtc active?',
  'localities.available_time_qtc': 'Available time qtc',
  'localities.closing_reason': 'Closing reason',
  'localities.extra_delivery_days': 'Additional delivery days',
  'localities.min_amount_extra_delivery_days': 'Minimum number of additional delivery days',
  'localities.help1': 'Location Name',
  'localities.help2': 'Select provider',
  'localities.help3': 'Enter the minimum cost for the minimum purchase.',
  'localities.help4': 'Enter the minimum amount for repurchase.',
  'localities.help5': 'Enter the minimum amount for free shipping.',
  'localities.help6': 'Enter the minimum amount for free shipping of the order in the first delivery.',
  'localities.help7': 'Enter the display order for the picking process',
  'localities.help8': '1 = Monday, 2 = Tuesday, 3 = Wednesday, 4 = Thursday, 5 = Friday, 6 = Saturday, 0 = Sunday.',
  'localities.help9': '1 = Deliveries today (12H), 2 = Deliveries tomorrow (24H), 3 = Delivery the day after tomorrow (48H)',
  'localities.help10': 'Shipping cost',
  'localities.help11': 'Customer shipping cost',
  'localities.help12': 'Customer min amount',
  'localities.help13': 'Qtc active?',
  'localities.help14': 'Available time qtc',
  'localities.help15': 'Closing reason',
  'localities.help16': 'Percentage of the order for the shipping cost ',

  'zipCodes.title': 'ZIP Code',
  'zipCodes.list': 'Zip Code List',
  'zipCodes.create': 'Create new',
  'zipCodes.placeholder': 'Search zip code',
  'zipCodes.titleModal': 'Create New Zip Code',
  'zipCodes.editModal': 'Edit Zip Code',
  'zipCodes.code': 'Code',
  'zipCodes.codeRequired': 'The code is required',
  'zipCodes.confirmDelete': 'Are you sure to delete this zip code?',
  'zipCodes.createLoading': 'Creating zip code...',
  'zipCodes.EditandoLoading': 'Editing the zip code...',
  'zipCodes.alertSuccess': 'The register has been saved',
  'zipCodes.alertdelete': 'The Zip Codehas been removed',

  'cities.title': 'Cities',
  'cities.titleList': 'Cities list',
  'cities.deleteMessage': 'Are you sure to delete this city?',
  'cities.createModal': 'Create city',
  'cities.editCity': 'Edit City',
  'cities.alertdelete': 'The city has been removed!',
  'cities.createLoading': 'Creating city....',
  'cities.EditandoLoading': 'Editing city....',
  'cities.alertSuccessEdit': 'The city has been successfully edited.',
  'cities.alertSuccess': 'The city has been created successfully.',
  'cities.NoCountry': 'It has no country',
  'cities.showAll': 'Show cities without supplier',

  'neighboarhood.title': 'Neighborhoods',
  'neighboarhood.titleList': 'Neighborhood List',
  'neighboarhood.createModal': 'Create neighborhood',
  'neighboarhood.editModal': 'Edit Neighborhood',
  'neighboarhood.confirmText': 'Are you sure to remove this neighborhood?',
  'neighboarhood.alertdelete': 'The neighborhood has been deleted',
  'neighboarhood.alertSuccessEdit': 'The neighborhood has been updated',
  'neighboarhood.EditandoLoading': 'Editing neighborhood...',
  'neighboarhood.createLoading': 'Creating neighborhood...',
  'neighboarhood.alertSuccess': 'The neighborhood has been created',

  'countries.title': 'Countries',
  'countries.country': 'Country',
  'countries.titleList': 'List of countries',
  'countries.iso': 'ISO (Country code)',
  'countries.alertdelete': 'The country has been deleted',
  'countries.editModal': 'Edit country',
  'countries.createModal': 'Create Country',
  'countries.createLoading': 'Creating country...',
  'countries.alertSuccess': 'The new country has been created',
  'countries.EditandoLoading': 'Editing country...',
  'countries.alertSuccessEdit': 'The country has been updated',
  'countries.confirmText': 'Are you sure you want to delete this country?',
  'countries.imageURL': 'URL image',

  'dailyCommitment.title': 'Daily commitment',
  'dailyCommitment.subTitle': 'Daily commercial commitment',
  'dailyCommitment.activities': 'Activations',
  'dailyCommitment.commitment': 'Commitment',
  'dailyCommitment.reactivations': 'Reactivations',
  'dailyCommitment.FirstOrder': 'First order',
  'dailyCommitment.Activation': 'Activation',
  'dailyCommitment.Reactivation': 'Reactivation',

  'salesman.title': 'Salesforce',
  'salesman.VisitToStores': 'Visits',
  'salesman.necessarySalesman': 'Necessary routes',
  'salesman.routes': 'Routes',
  'salesman.storesBySalesman': 'Stores by Salesman',
  'salesman.detailByProcess': 'Process Detail # ',
  'salesman.createProcess': 'Create process',
  'salesman.salesman1': 'How many visits per Salesman?',
  'salesman.createOK': 'Are you sure to create a visit process for this provider?',
  'salesman.mapModal': 'Route map #',
  'salesman.ToAssign': 'Assign',
  'salesman.ToAssignModal': 'create new route',
  'salesman.selectSalesman': 'Select the salesman: ',
  'salesman.selectRequired': 'You must select a salesman to assign the route',
  'salesman.salesman': 'Salesforce',
  'salesman.salesmans': 'Salesforce',
  'salesman.stores': 'Stores',
  'salesman.success': 'The salesman has been assigned to the route',
  'salesman.noName': 'Not assigned',
  'salesman.noNumber': 'You must insert how many stores should be visited by salesman',
  'salesman.noMore': 'No more than 24 stores can be assigned to routes',
  'salesman.dateLastOrder': 'Last order date',
  'salesman.CreatePolygon': 'Create a polygon to assign a new visit process',
  'salesman.Finalize': 'Finalize',
  'salesman.searchStores': 'Search Stores',
  'salesman.deleteRoute': 'Are you sure to delete the route?',
  'salesman.errorNoStores': 'This route has no stores assigned',
  'salesman.detailMap2': 'Route Complete map',
  'salesman.selecrtSalesmans': 'Select the salesman to show your stores',
  'salesman.deleteMessage': 'Are you sure to delete the process?',
  'salesman.successDelete': 'The process has been deleted!',
  'salesman.createNewRoute': 'Create route by polygon',
  'salesman.createNewRouteXlsx': 'Create route by store list',
  'salesman.createRoutesuccess': 'The route has been created successfully!',
  'salesman.detailMap': 'Show full map',

  'givepointsOrders.title': 'Rewards for Orders',
  'givepointsOrders.subTitle': 'Module to give points to stores',
  'givepointsOrders.button': 'Give away points',
  'givepointsOrders.detailTransactions': 'Transaction detail',
  'givepointsOrders.textConfirm': 'Are you sure to give the points to the store?',
  'givepointsOrders.responsable': 'Responsible',
  'givepointsOrders.noResponsable': 'Without responsible',
  'givepointsOrders.desc1': 'Product in bad condition',
  'givepointsOrders.desc2': 'Wrong product',
  'givepointsOrders.desc3': 'Missing product',
  'givepointsOrders.desc4': 'Catalogue price error',
  'givepointsOrders.desc5': 'Shipping cost compensation',
  'givepointsOrders.desc6': 'Order delivered to other store',

  'permissions.title': 'Administrator permissions section by role',
  'permissions.createRole': 'Create a new role',
  'permissions.role': 'Rol',
  'permissions.permissions': 'permissions',
  'permissions.success': 'The new role has been created',
  'permissions.popConfirm': 'Are you sure you change the permission for this role?',
  'permissions.popConfirmUser': 'Are you sure to change the permission for this user?',
  'permissions.exito': 'Permissions have been changed',

  'Q2CTitle': 'Q2C',
  'Q2CTitle.detail': 'Store detail',

  'Q2CCatalog.update': 'ALLY CATALOGUE',
  'Q2CCatalog.ally': 'Select the ally',
  'Q2CCatalog.locality': 'Select the location',

  'Q2COrders.status0': 'Pending',
  'Q2COrders.status1': 'Accepted',
  'Q2COrders.status4': 'Delivered',
  'Q2COrders.confirm': '¿Sure to change status to order?',

  'registrationPurchaseOrders.title': 'Purchase Orders',
  'registrationPurchaseOrders.approvePurchaseOrders': 'Approve purchase orders',
  'registrationPurchaseOrders.approvePurchaseOrdersAllCities': 'Approve purchase orders from all cities',
  'registrationPurchaseOrders.subTitle': 'Purchase Order Registration Form',
  'registrationPurchaseOrders.listTitle': 'List of Purchase Order Records',
  'registrationPurchaseOrders.confirmDelete': 'Do you want to delete this product?',
  'registrationPurchaseOrders.messageProducts': 'First you must save all products to continue',
  'registrationPurchaseOrders.addRegister': 'Add register',
  'registrationPurchaseOrders.saveOrder': 'Save the Purchase Record',
  'registrationPurchaseOrders.warehouse': 'Select the warehouse:',
  'registrationPurchaseOrders.cancelOrder': 'You must enter the cancellation reason',
  'registrationPurchaseOrders.questionModal': 'Are you sure to save this purchase register?',
  'registrationPurchaseOrders.questionModal2': 'Are you sure to update this purchase register?',
  'registrationPurchaseOrders.success': 'Purchase register has been saved',
  'registrationPurchaseOrders.confirmAproved': 'Are you sure you approve this order?',
  'registrationPurchaseOrders.confirmCancel': 'Are you sure to cancel this order?',
  'registrationPurchaseOrders.confirmDelivered': 'Are you sure to mark as delivered this order?',
  'registrationPurchaseOrders.confirmDelivered2': 'All items will be marked as received',
  'registrationPurchaseOrders.confirmSuccesfull': 'This order has been delivered',
  'registrationPurchaseOrders.buttonSend': 'Mark order as delivered',
  'registrationPurchaseOrders.confirmAprovedSuccess': 'The order was marked as approved',
  'registrationPurchaseOrders.confirmAprovedCancel': 'The order was marked as canceled',
  'registrationPurchaseOrders.detailOrder': 'Purchase order detail',
  'registrationPurchaseOrders.totalReceived': 'Total received',
  'registrationPurchaseOrders.invoiceRequired': 'You must have an invoice number to change to delivered',
  'registrationPurchaseOrders.updateOrder': 'Update Order',
  'registrationPurchaseOrders.noSaved': 'There are products with pending fields to fill',
  'registrationPurchaseOrders.EditAndreceive': 'Edit and Receive',
  'registrationPurchaseOrders.receive': 'Receive Product',
  'registrationPurchaseOrders.DeliveryType': 'Delivery type',
  'registrationPurchaseOrders.PartialDelivery': 'Partial delivery',
  'registrationPurchaseOrders.FinalDelivery': 'Final delivery',
  'registrationPurchaseOrders.ConfirmDelivery': 'Confirm Delivery',
  'registrationPurchaseOrders.recivedProduct': 'Are you sure you receive this product?',
  'registrationPurchaseOrders.observationNull': 'You must write an observation of the change of values',
  'registrationPurchaseOrders.typeNull': 'You must select a delivery type',
  'registrationPurchaseOrders.InvoiceNull': 'This order does not have an invoice number',
  'registrationPurchaseOrders.InvoiceMessage': 'Add the invoice and update the order to continue..',
  'registrationPurchaseOrders.succesItem': 'Products were added to inventory successfully.',
  'registrationPurchaseOrders.noProducts': 'Products not found',
  'registrationPurchaseOrders.messageError': 'Remember to add the product detail.',
  'registrationPurchaseOrders.note': 'NOTE: The products marked with yellow color require selecting the specifications for the purchase order upload.',
  'registrationPurchaseOrders.addDiscount': 'Add Discount',
  'registrationPurchaseOrders.addDiscount2': 'Add Discount to the order ',
  'registrationPurchaseOrders.followingPurchaseOrder': 'The following purchase orders were created successfully',
  'registrationPurchaseOrders.confirmAddDiscount': 'Are you sure to add the discount to this order?',
  'registrationPurchaseOrders.errorRecibeOrder': 'This order already has all its products in received status',
  'registrationPurchaseOrders.runBillingByDateRange': 'Execute billing by date range',
  'registrationPurchaseOrders.range': 'Select date range',
  'registrationPurchaseOrders.selectProviders': 'Select providers',
  'registrationPurchaseOrders.confirmModalRanges': 'Are you sure of executing billing by date range?',
  'registrationPurchaseOrders.successCreateRanges': 'The date ranges for billing have been saved successfully.',
  'registrationPurchaseOrders.selectFileXlsx': 'Select the product file (XLSX)',

  'r.form.billingInformation': 'Billing information',
  'r.form.deliveryInformation': 'Delivery information',
  'r.form.approved': 'Approved by',
  'r.form.observations': 'Comments',
  'r.form.originalDeliveryDate': 'Original delivery date',
  'r.form.deliveryDate': 'Delivery date',
  'r.form.eanSales': 'SKU Sales',
  'r.form.eanPacking': 'SKU packing',
  'r.form.packing': 'Packing',
  'r.form.totalTaxes': 'Total taxes',
  'r.form.subTotalAmount': 'Sub total',
  'r.form.newProduct': 'New product',
  'r.form.InventoryDays': 'Inventory days',
  'r.status.0': 'Draft',
  'r.status.1': 'Pending to Approve',
  'r.status.2': 'Approved',
  'r.status.3': 'Canceled',
  'r.status.4': 'Received',
  'r.status.5': 'Completed',

  'appComparative.title': 'Reports',
  'appComparative.createReport': 'Create Report',
  'appComparative.createReportQuestion': 'Are you sure to create a new report?',
  'appComparative.createReportSuccess': 'A new report has been created, enter to edit it!',
  'appComparative.creator': 'Creator',
  'appComparative.reportDetail': 'Report detail',
  'appComparative.priceComparison': 'Price comparison',
  'appComparative.app': 'Application',
  'appComparative.addApp': 'Add Competitor App',
  'appComparative.questionSend': 'Are you sure to save?',
  'appComparative.updateSuccess': 'Registration has been updated!',
  'appComparative.sendEmailButton': 'Send email',
  'appComparative.detailModal': 'Price comparison detail',
  'appComparative.cancelEditModal': 'Are you sure to cancel the editing process?',

  'coupons.title': 'Coupons',
  'coupons.subTitle': 'Coupon list',
  'coupons.buttonAdd': 'Create coupon',
  'coupons.titleForm': 'Coupon form',
  'coupons.coupon': 'Coupon',
  'coupons.prizeType': 'Reward Type',
  'coupons.prize': 'Coupon Amount',
  'coupons.prize2': 'Discount Percentage',
  'coupons.ruleMinAmount': 'Minimum Order Amount to be able to redeem it',
  'coupons.ruleMaxAmount': 'Maximum Gift Amount for Coupon',
  'coupons.productAtLeast': '# Minimum of products that must be included',
  'coupons.target': 'Target',
  'coupons.general': 'General',
  'coupons.listUsers': 'User list',
  'coupons.type': 'Coupon Type',
  'coupons.byProducts': 'By products in the cart',
  'coupons.byCategory': 'By category products',
  'coupons.byDepartment': 'By department products',
  'coupons.monto': 'Amount',
  'coupons.purchasePercentage': 'Purchase Percentage',
  'coupons.createCoupon': 'Create coupon',
  'coupons.couponModal': 'Are you sure to create the coupon?',
  'coupons.successCreate': 'The coupon has been created',
  'coupons.quantityTotal': 'Total Quantity Counpon uses',
  'coupons.quantityTotalByUser': 'Maximum Coupon uses per User',
  'coupons.allCountry': 'Apply the coupon across the country',
  'coupons.allCountryOK': 'Keep in mind that users can use the coupon in any of the providers in the country.',

  'baskets.name': 'Baskets',
  'baskets.permissionsCreate': 'Create baskets',
  'baskets.warehouse': 'Warehouse',
  'baskets.code': 'Code',
  'baskets.listName': 'Basket List',
  'baskets.createButton': 'Create basket',
  'baskets.editButton': 'Edit basket',
  'baskets.selectedWarehouse': 'Select the warehouse',
  'baskets.confirmAlert': 'Are you sure to create a new basket?',
  'baskets.confirmEditAlert': 'Are you sure to update the basket?',
  'baskets.changeWarehouse': 'Change warehouse',
  'baskets.returnBaskets': 'Baskets Returns',
  'baskets.returnBasketsButton': 'Baskets Returns',
  'baskets.pilotName': 'Driver Name',
  'baskets.return': 'Receive baskets',
  'baskets.errorCompleteProcess': 'You must receive at least one basket.',
  'baskets.confirmDeliveryBaskets': 'Sure to receive the baskets',
  'baskets.openProcess': 'Opened Process',
  'baskets.closed': 'Closed',
  'baskets.status0': 'No news',
  'baskets.status1': 'Lost',
  'baskets.status2': 'Damaged',
  'baskets.basket': 'Basket',
  'baskets.basketSuccessModal': 'Baskets returned successfully.',
  'baskets.quantity': 'Baskets quantity',

  'print.name': 'Ip printer',

  'ads.title': 'Ads',
  'ads.createAd': 'Create Ads',
  'ads.editAd': 'Edit Ads',
  'ads.formAds': 'Ad forms',
  'ads.inAppMesagge': 'inAppMesagge',
  'ads.acept': 'Are you sure to create the ad?',
  'ads.aceptEdit': 'Are you sure to edit the ad?',
  'ads.aceptEditState': 'Are you sure to change the status of the ad?',
  'ads.successCreate': 'The ad has been created successfully!',
  'ads.successEdit': 'The ad has been edited successfully!',
  'ads.available': 'Available',

  'orderReceipts.title': 'Order Receipts',

  'orderTransaction.titlle': 'Order Transactions',
  'orderTransaction.createdAt': 'Created At',
  'orderTransaction.amount': 'Amount',
  'orderTransaction.totalAmount': 'Total Amount',
  'orderTransaction.transacionId': 'Transaction ID',
  'orderTransaction.paymentMethod': 'Payment Method',
  'orderTransaction.bankAccount': 'Bank Account',
  'orderTransaction.status': 'Status',


}

export default {
  locale: 'en-US',
  antdData,
  localeData,
  messages,
}
