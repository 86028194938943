export default {
  apiKey: 'AIzaSyCYOTJaYc4EX0C3qkz423L_5rOLUqrfWwc',
  authDomain: 'quqo-573cd.firebaseapp.com',
  databaseURL: 'https://quqo-573cd.firebaseio.com',
  projectId: 'quqo-573cd',
  storageBucket: 'quqo-573cd.appspot.com',
  messagingSenderId: '835475485816',
  appId: '1:835475485816:web:ba0f305d53e52736eb298d',
  measurementId: 'G-DWZ6XC5Z9N',
}
