import React from 'react'
import { IntlProvider, addLocaleData } from 'react-intl'
import { connect } from 'react-redux'
import { ConfigProvider } from 'antd';
import english from 'locales/en-US'
import spanish from 'locales/es-CO'
import vietnamita from 'locales/vi-VN'

addLocaleData(english.localeData)
addLocaleData(spanish.localeData)
addLocaleData(vietnamita.localeData)

const locales = {
  'en-US': english,
  'es-CO': spanish,
  'vi-VN': vietnamita,
}

@connect(({ settings }) => ({ settings }))
class Localization extends React.Component {
  render() {
    const {
      children,
      settings: { locale },
    } = this.props
    const currentLocale = locales[locale]

    return (
      <ConfigProvider locale={currentLocale.antdData}>
        <a-config-provider locale={currentLocale.antdData}>
          <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
            {children}
          </IntlProvider>
        </a-config-provider>
      </ConfigProvider>
    )
  }
}

export default Localization
