import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import LoadingPage from 'components/LoaderPageComponent'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // permissionsAdmin
  {
    path: '/admin/permissionsAdmin',
    component: loadable(() => import('pages/dashboard/permissionsAdmin')),
  },

  // Admin Quqo Pages
  {
    path: '/admin/promotions/:type',
    component: loadable(() => import('pages/dashboard/promotions')),
  },
  {
    path: '/admin/users',
    component: loadable(() => import('pages/dashboard/users/allUserList')),
  },
  {
    path: '/admin/whiteUserList',
    component: loadable(() => import('pages/dashboard/users/whiteUsersList')),
  },
  {
    path: '/admin/coordinatorUsers',
    component: loadable(() => import('pages/dashboard/users/coordinatorUsers')),
  },
  {
    path: '/admin/uploadFileUsers',
    component: loadable(() => import('pages/dashboard/users/uploadFileUsers')),
  },
  {
    path: '/admin/editUser/:userID',
    component: loadable(() => import('pages/dashboard/users/allUserList/EditUserView')),
  },
  {
    path: '/admin/products',
    component: loadable(() => import('pages/dashboard/products/productsList')),
  },
  {
    path: '/admin/productsCards/:filter/:pagination',
    component: loadable(() => import('pages/dashboard/products')),
  },
  {
    path: '/admin/productsTaxes',
    component: loadable(() => import('pages/dashboard/products/productsTaxes')),
  },
  {
    path: '/admin/catalogList',
    component: loadable(() => import('pages/dashboard/products/catalogList')),
  },
  {
    path: '/admin/editProduct/:productID/:filter/:pagination',
    component: loadable(() => import('pages/dashboard/products/productsList/EditProductPage')),
  },
  {
    path: '/admin/configProduct/:productID/:providerID/:filter/:pagination/:allyID',
    component: loadable(() => import('pages/dashboard/products/configProduct')),
  },
  {
    path: '/admin/scales/:productID/:providerID/:filter/:pagination/:allyID',
    component: loadable(() => import('pages/dashboard/products/scalesProduct')),
  },
  {
    path: '/admin/promotionsForm/:promoId/:type',
    component: loadable(() => import('pages/dashboard/promotions/promotionForm')),
    exact: true,
  },
  {
    path: '/admin/specialPromotionForm/:promoId',
    component: loadable(() => import('pages/dashboard/promotions/specialPromotionForm')),
    exact: true,
  },
  {
    path: '/admin/salesmanTracking',
    component: loadable(() => import('pages/dashboard/salesmanViews/salesmanTracking')),
  },
  // Daily Commitment
  {
    path: '/admin/dailyCommitment',
    component: loadable(() => import('pages/dashboard/salesmanViews/dailyCommitments')),
    exact: true,
  },
  // Visit Process
  {
    path: '/admin/visitProcess',
    component: loadable(() => import('pages/dashboard/salesmanViews/visitProcess')),
    exact: true,
  },
  // Visit Process create polygon
  {
    path: '/admin/createPolygon/:idProcess',
    component: loadable(() =>
      import('pages/dashboard/salesmanViews/visitProcess/detail/createPolygon'),
    ),
    exact: true,
  },
  {
    path: '/admin/createRouteByXlsx/:idProcess',
    component: loadable(() =>
      import('pages/dashboard/salesmanViews/visitProcess/detail/createRouteByXlsx'),
    ),
    exact: true,
  },
  {
    path: '/admin/visitProcess/detail/:processID',
    component: loadable(() => import('pages/dashboard/salesmanViews/visitProcess/detail')),
    exact: true,
  },
  {
    path: '/admin/orders',
    component: loadable(() => import('pages/dashboard/orders/ordersAdmin')),
    exact: true,
  },
  {
    path: '/admin/orderReceipts',
    component: loadable(() => import('pages/dashboard/orders/orderReceipts')),
    exact: true,
  },
  {
    path: '/admin/listPurchaseOrders',
    component: loadable(() => import('pages/dashboard/registrationPurchaseOrders')),
    exact: true,
  },
  {
    path: '/admin/registrationPurchaseOrders/:idPurchase',
    component: loadable(() => import('pages/dashboard/registrationPurchaseOrders/formView')),
    exact: true,
  },
  {
    path: '/admin/tableOrder/:idPurchase',
    component: loadable(() => import('pages/dashboard/registrationPurchaseOrders/pageTableOrder')),
    exact: true,
  },
  {
    path: '/admin/ordenDetail/:idOrden/:type',
    component: loadable(() => import('pages/dashboard/orders/ordersAdmin/ordenDetail')),
    exact: true,
  },
  {
    path: '/admin/OrderItems/:idOrder/:provider',
    component: loadable(() => import('pages/dashboard/orders/orderItems')),
    exact: true,
  },
  {
    path: '/admin/givePointsOrders',
    component: loadable(() => import('pages/dashboard/orders/givePointsOrders')),
    exact: true,
  },
  {
    path: '/admin/mapInTransito/:idOrden',
    component: loadable(() => import('pages/dashboard/orders/ordersAdmin/mapTransito')),
    exact: true,
  },
  {
    path: '/admin/updateCatalog',
    component: loadable(() => import('pages/dashboard/products/updateCatalog')),
    exact: true,
  },

  // MISIONES
  {
    path: '/admin/missionsDashboard',
    component: loadable(() => import('pages/dashboard/missions/dashboard')),
  },
  {
    path: '/admin/missionsTable',
    component: loadable(() => import('pages/dashboard/missions/missionsTable')),
  },
  {
    path: '/admin/missionsForm/:misionID',
    component: loadable(() => import('pages/dashboard/missions/missionsForm')),
  },
  {
    path: '/admin/analiticsByMision/:misionID',
    component: loadable(() => import('pages/dashboard/missions/missionsTable/analiticsByMision')),
  },

  // ADS
  {
    path: '/admin/ads',
    component: loadable(() => import('pages/dashboard/ads/list')),
  },

  // Push Notifications
  {
    path: '/admin/notificationsList',
    component: loadable(() => import('pages/dashboard/pushNotifications')),
  },
  {
    path: '/admin/notificationsForm/:idNotification',
    component: loadable(() => import('pages/dashboard/pushNotifications/notificationsForm')),
  },
  {
    path: '/admin/smsForm/:id',
    component: loadable(() => import('pages/dashboard/pushNotifications/smsForm')),
  },

  // Campañas
  {
    path: '/admin/campaigns',
    component: loadable(() => import('pages/dashboard/campaigns')),
  },
  {
    path: '/admin/campaignsForm/:id',
    component: loadable(() => import('pages/dashboard/campaigns/campaignsForm')),
  },
  // CUPONES
  {
    path: '/admin/coupons',
    component: loadable(() => import('pages/dashboard/coupons')),
  },
  {
    path: '/admin/couponsForm/:id',
    component: loadable(() => import('pages/dashboard/coupons/couponsForm')),
  },
  // Inventario
  {
    path: '/admin/inventario',
    component: loadable(() => import('pages/dashboard/inventario/dashboard')),
  },
  {
    path: '/admin/baskets',
    component: loadable(() => import('pages/dashboard/baskets')),
  },
  {
    path: '/admin/upload/:warehouse',
    component: loadable(() => import('pages/dashboard/inventario/uploadFile')),
  },
  {
    path: '/admin/OrdersToReturnProducts',
    component: loadable(() => import('pages/dashboard/OrdersToReturnProducts')),
  },

  // Brands
  {
    path: '/admin/brands',
    component: loadable(() => import('pages/dashboard/brands/dashboard')),
  },
  {
    path: '/admin/UploadFileBrands',
    component: loadable(() => import('pages/dashboard/brands/uploadFile')),
  },

  // Zones
  {
    path: '/admin/zones',
    component: loadable(() => import('pages/dashboard/zones/dashboard')),
  },
  {
    path: '/admin/zonesForm/:idZone/:name/:localityId',
    component: loadable(() => import('pages/dashboard/zones/zonesForm')),
  },

  // Zones
  {
    path: '/admin/holidays',
    component: loadable(() => import('pages/dashboard/holidays')),
  },

  // providers
  {
    path: '/admin/providers',
    component: loadable(() => import('pages/dashboard/providers')),
  },

  // retailers
  {
    path: '/admin/retailers',
    component: loadable(() => import('pages/dashboard/retailers')),
  },

  // supplies
  {
    path: '/admin/supplies',
    component: loadable(() => import('pages/dashboard/supplies')),
  },

  // processPicker
  {
    path: '/admin/pickerProcess',
    component: loadable(() => import('pages/dashboard/pickerProcess')),
    exact: true,
  },
  {
    path: '/admin/pickerProcess/details/:dateOrders/:type',
    component: loadable(() => import('pages/dashboard/pickerProcess/details')),
    exact: true,
  },
  {
    path: '/admin/routeOptimizations/detail/:dateOrders/:routeID',
    component: loadable(() => import('pages/dashboard/routeOptimizations/detail')),
    exact: true,
  },

  // AVAILABLE_ZIP_CODES
  {
    path: '/admin/zipCodes',
    component: loadable(() => import('pages/dashboard/availablesZipCodes')),
    exact: true,
  },

  // processInventory
  {
    path: '/admin/inventoryProcess',
    component: loadable(() => import('pages/dashboard/inventoryProcess')),
  },

  // Localidades
  {
    path: '/admin/localities',
    component: loadable(() => import('pages/dashboard/localities')),
  },

  // neighboarhood
  {
    path: '/admin/neighboarhood',
    component: loadable(() => import('pages/dashboard/neighboarhoods')),
  },

  // Countries
  {
    path: '/admin/countries',
    component: loadable(() => import('pages/dashboard/countries')),
  },

  // Vistas Publicas
  {
    path: '/Activations/:nameCity/',
    component: loadable(() => import('pages/DefaultPages/Activations')),
  },
  // Cities

  {
    path: '/admin/cities',
    component: loadable(() => import('pages/dashboard/cities')),
  },
  {
    path: '/admin/appComparative',
    component: loadable(() => import('pages/dashboard/appComparative')),
  },

  // System Pages
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/loginMissionAnalitics/:missionID',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },

  // resetPassword
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
  },

  {
    path: '/reset/:nameUser/:token',
    component: loadable(() => import('pages/DefaultPages/ResetPassword')),
  },
  // resetPassword in sesión
  {
    path: '/pages/dashboard/resetPassword',
    component: loadable(() => import('pages/dashboard/resetPassword')),
  },
  {
    path: '/pages/noPage',
    component: loadable(() => import('pages/dashboard/noPage')),
    exact: true,
  },

  // Stores Q2C map
  {
    path: '/pages/dashboard/q2c/orders',
    component: loadable(() => import('pages/dashboard/q2c/orders')),
    exact: true,
  },
  {
    path: '/pages/dashboard/q2c/users',
    component: loadable(() => import('pages/dashboard/q2c/users')),
    exact: true,
  },
  {
    path: '/pages/dashboard/q2c/users/createStore/:credential',
    component: loadable(() => import('pages/dashboard/q2c/users/createStore')),
    exact: true,
  },
  {
    path: '/pages/dashboard/q2c/products',
    component: loadable(() => import('pages/dashboard/q2c/products')),
    exact: true,
  },
  {
    path: '/admin/dashboard/q2c/productsEdit/:productID/:allyID',
    component: loadable(() => import('pages/dashboard/q2c/products/productsEdit')),
  },
  {
    path: '/pages/dashboard/q2c/stores',
    component: loadable(() => import('pages/dashboard/q2c/stores')),
    exact: true,
  },
  {
    path: '/pages/dashboard/q2c/uploadFile',
    component: loadable(() => import('pages/dashboard/q2c/catalogue')),
    exact: true,
  },
]

const mapStateToProps = ({ menu, user }) => ({
  menuData: menu.menuLeftData,
  user,
})

@connect(mapStateToProps)
class Router extends React.Component {
  render() {
    const { history, menuData, user } = this.props

    if (menuData.length === 0 && user.name !== '')
      return (
        <div align="center">
          <LoadingPage />
        </div>
      )

    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/admin/orders" />} />
            {routes.map((route) => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
