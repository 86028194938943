import amplitude from 'amplitude-js'
import firebase from 'firebase'

let firebaseInstance
const JSONParsebaseURL = JSON.parse(process.env.REACT_APP_API_URL_V2);
const baseURLUse = localStorage.getItem('base_url_use') || "prod";
const API_SERVER = JSONParsebaseURL[baseURLUse];
const API_SERVER_PROD = 'https://api.quqo.com/v2'

export function initTracker() {
  if (API_SERVER === API_SERVER_PROD) {
    try {
      amplitude.getInstance().init('a92ca3a2b5db7a5b5b2a97d0b64d4286')
    } catch (e) {
      console.log(e)
    }

    firebaseInstance = firebase.analytics()
  }
}

export function setUserProperty(properties) {
  if (API_SERVER === API_SERVER_PROD) {
    const identify = new amplitude.Identify()
      .add('city', properties.city.toString())
      .add('name', properties.name)
      .add('country', properties.country)
      .add('user_id', properties.id)
      .add('email', properties.email)
      .add('phone', properties.phone.toString())
      .add('provider_id', properties.provider_id)
      .add('role', properties.role)

    amplitude.getInstance().identify(identify)

    amplitude.getInstance().setUserProperties(properties)
    amplitude.getInstance().setUserId(properties.id)

    firebaseInstance.setUserId(properties.id)
  }
}

export function saveTrackerEvent(section, providerID) {
  if (API_SERVER === API_SERVER_PROD) {
    let properties = {}
    if (providerID !== 0) {
      properties = { provider_id: providerID }
    }

    amplitude.getInstance().logEvent(section, properties)

    firebaseInstance.logEvent(section, properties)
  }
}

export function saveTrackerEventWithProperties(nameEvent, properties) {
  if (API_SERVER === API_SERVER_PROD) {
    amplitude.getInstance().logEvent(nameEvent, properties)

    firebaseInstance.logEvent(nameEvent, properties)
  }
}
