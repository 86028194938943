import React from 'react'
import { connect } from 'react-redux'
import { Menu, Dropdown, Avatar } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import styles from './style.module.scss'

@connect(({ city, user }) => ({ city, user }))
class ProfileMenu extends React.Component {
  logout = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  render() {
    const { user, city } = this.props

    const CityLabel = () => {
      if (user.role !== 'SUPERADMIN') {
        return (
          <div>
            <strong>
              <FormattedMessage id="globals.city" />:{' '}
            </strong>
            {city.city}
            <br />
          </div>
        )
      }
      return false
    }


    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>
            <FormattedMessage id="topBar.profileMenu.hello" />, {user.name || 'Anonymous'}
          </strong>
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.role" />:{' '}
            </strong>
            {user.role}
          </div>

          <CityLabel />
        </Menu.Item>
        <Menu.Item>
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.email" />:{' '}
            </strong>
            {user.email}
            <br />
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="https://docs.google.com/a/quqo.com/viewer?url=https://qtienda-catalog-apsoutheast.s3.ap-southeast-1.amazonaws.com/terms/Terms.pdf" target="_blank" rel="noopener noreferrer">

            <FormattedMessage id="topBar.profileMenu.terms" />
          </a>
        </Menu.Item>
        <Menu.Item>
          <a href="https://docs.google.com/a/quqo.com/viewer?url=https://qtienda-catalog-apsoutheast.s3.ap-southeast-1.amazonaws.com/terms/Complaint.pdf" target="_blank" rel="noopener noreferrer">

            <FormattedMessage id="topBar.profileMenu.sales_policies" />
          </a>
        </Menu.Item>
        <Menu.Item>
          <a href="https://docs.google.com/a/quqo.com/viewer?url=https://qtienda-catalog-apsoutheast.s3.ap-southeast-1.amazonaws.com/terms/Privacy.pdf" target="_blank" rel="noopener noreferrer">

            <FormattedMessage id="topBar.profileMenu.privacy_policies" />
          </a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="/#/pages/dashboard/resetPassword">
            <i className={`${styles.menuIcon} icmn-key2`} />
            <FormattedMessage id="topBar.profileMenu.ressetPassword" />
          </a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="javascript: void(0);" onClick={this.logout}>
            <i className={`${styles.menuIcon} icmn-exit`} />
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        </Menu.Item>
      </Menu>
    )

    return (
      <>
        <Dropdown overlay={menu} trigger={['click']}>
          <div className={styles.dropdown}>
            <Avatar
              className={styles.avatar}
              size={40}
              style={{ backgroundColor: 'white' }}
              src="resources/quqoImages/userAvatar.svg"
            />
          </div>
        </Dropdown>
      </>
    )
  }
}

export default injectIntl(ProfileMenu)
