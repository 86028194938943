import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './style.module.scss'


const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.inner}>
      <div className={styles.bottom}>
        <div className="row">
          <div className="col-6">
            <p>
              <strong><FormattedMessage id="footer.title" />{global.appVersion}</strong>
            </p>
            <p>
              <FormattedMessage id="footer.subTitle" />
            </p>
          </div>
          <div className="col-6">
            <div className={styles.copyright}>
              <span>
                <a href="https://quqo.com/" target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="footer.url" />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
