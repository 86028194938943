import React from 'react';
import { FormattedMessage } from 'react-intl'
import styles from '../../pages/user/login/style.module.scss'

const LoadingPage = () => {
  return (
    <section
      className={`${styles.login} ${styles.fullscreen}`}
      style={{ backgroundColor: `#ffffff`}}
    >
      <div className={styles.content}>
        <img src="resources/quqoImages/loader.gif" alt="" />
        <h2 style={{color: '#e9425b'}}><FormattedMessage id="globals.loading" /></h2>
      </div>
    </section>
  );
}

export default LoadingPage;
