import React from 'react'
import ReactDOM from 'react-dom'

import { createHashHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
// import { logger } from 'redux-logger'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { initTracker } from 'utils/tracker'
import firebase from 'firebase'
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import * as serviceWorker from './serviceWorker'

import App from './App'
import firebaseConfig from './firebase-config'

const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [thunk, sagaMiddleware, routeMiddleware]
// if (process.env.NODE_ENV === 'development' && true) {
//   middlewares.push(logger)
// }

Sentry.init({
  dsn: 'https://7182bec568444ba09443240d234f214a@o519568.ingest.sentry.io/5679316',
  integrations: [new Integrations.BrowserTracing()],

  tracesSampleRate: 1.0,
})

const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

firebase.initializeApp(firebaseConfig)

initTracker()

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.register()

export { store, history }
