import React from 'react'
import { Select, Menu, Dropdown, Avatar, Alert } from 'antd'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import SelectedProvider from 'components/UtilsComponents/SelectedProvider'
import ProfileMenu from './ProfileMenu'
import LanguageSelector from './LanguageSelector'
import styles from './style.module.scss'
import { getProvidersService } from '../../../services/providers'
import menuOptions from '../../../menu'

const { Option } = Select

let baseURLUse = localStorage.getItem('base_url_use') || "prod";

if (baseURLUse === 'prod') {
  baseURLUse = 'dev';
}

@connect(({ city, user, settings }) => ({ city, user, settings }))
class TopBar extends React.Component {
  state = {
    nameUser: '',
    typeMenu: '',
    changeProviders: false,
    showOnlyProvidersByCountry: false,
    changeTypeServices: false,
  }

  componentDidMount() {
    const { user } = this.props

    if (user.permissions) {
      const arrayPermissions = user.permissions.split(',')
      if (arrayPermissions.length > 0) {
        this.setState({
          changeProviders: arrayPermissions.filter((p) => p === 'CHANGE_CITY_WRITE').length > 0,
          showOnlyProvidersByCountry:
            arrayPermissions.filter((p) => p === 'SHOW_ONLY_PROVIDERS_BY_COUNTRY_WRITE').length > 0,
          changeTypeServices: arrayPermissions.filter((p) => p === 'CHANGE_TYPE_WRITE').length > 0,
        })
      }
    }

    this.setStateData()
  }

  setStateData = () => {
    const { typeMenu } = this.state
    const { user, city } = this.props
    this.setState(
      {
        nameUser: user.name,
        typeMenu: typeMenu === '' ? city.type_menu : typeMenu,
      },
      () => this.setCityProvider(user, city.city),
    )
  }

  setCityProvider = (user, city) => {
    const { dispatch } = this.props
    if (city) return

    getProvidersService().then((providers) => {
      const optionSelected = providers.filter(
        (option) => option.id_provider === user.id_provider,
      )[0]
      dispatch({
        type: 'city/SET_STATE',
        payload: optionSelected,
      })

      localStorage.setItem('userCity', JSON.stringify(optionSelected))
    })
  }

  changeTypeMenu = (value) => {
    const { dispatch, city: optionSelected, user, history } = this.props

    optionSelected.type_menu = value

    localStorage.setItem('userCity', JSON.stringify(optionSelected))

    this.setState({ typeMenu: value })

    dispatch({
      type: 'city/SET_STATE',
      payload: optionSelected,
    })

    dispatch({
      type: 'menu/GET_DATA',
    })

    const permissions = user.permissions.split(',')
    let sectionObject = null

    const menuOptionsMenu = menuOptions[value].filter((option) => option.url !== '')

    menuOptionsMenu.forEach((m) => {
      if (sectionObject === null) {
        permissions.forEach((p) => {
          if (p === m.key) sectionObject = m
        })
      }
    })
    if (sectionObject.url != null) history.push(sectionObject.url)
  }

  changeCity = (dataCity) => {
    const { dispatch } = this.props
    dispatch(dataCity)
  }

  render() {
    const { nameUser, typeMenu, changeProviders, showOnlyProvidersByCountry, changeTypeServices } =
      this.state
    const { settings, user } = this.props

    const CitySelect = () => {
      if (changeProviders) {
        if (!settings.isMobileView) {
          return (
            <>
              <SelectedProvider
                changeCity={this.changeCity}
                country={user.country}
                appReference={user.app_reference}
                showOnlyProvidersByCountry={showOnlyProvidersByCountry}
              />{' '}
              &nbsp;&nbsp;
              {changeTypeServices ? (
                <Select
                  value={typeMenu}
                  className="width-100p"
                  style={{ width: 150 }}
                  onChange={this.changeTypeMenu}
                >
                  <Option value="STORE">
                    <FormattedMessage id="globals.quqoStore" />
                  </Option>
                  <Option value="Q2C">Q2C</Option>
                </Select>
              ) : null}
            </>
          )
        }

        const menu = (
          <>
            <Menu selectable={false}>
              <Menu.Item>
                <SelectedProvider
                  changeCity={this.changeCity}
                  country={user.country}
                  appReference={user.app_reference}
                  showOnlyProvidersByCountry={showOnlyProvidersByCountry}
                />
              </Menu.Item>

              {changeTypeServices ? (
                <Menu.Item>
                  <Select
                    value={typeMenu}
                    className="width-100p"
                    style={{ width: 150 }}
                    onChange={this.changeTypeMenu}
                  >
                    <Option value="STORE">
                      <FormattedMessage id="globals.quqoStore" />
                    </Option>
                    <Option value="Q2C">Q2C</Option>
                  </Select>
                </Menu.Item>
              ) : null}
            </Menu>
          </>
        )

        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <div className={styles.dropdown}>
              <Avatar
                className={styles.avatar}
                size={40}
                style={{ backgroundColor: 'white' }}
                src="resources/quqoImages/shuffle.png"
              />
            </div>
          </Dropdown>
        )
      }
      return null
    }

    return (
      <div className={styles.topbar}>
        <div className="mr-auto">
          <CitySelect />
        </div>
        {
          process.env.NODE_ENV === "development" ?
            <Alert message={baseURLUse === "dev" ? "DESARROLLO" : "QA"} type="error" /> : null
        }

        {!settings.isMobileView ? (
          <div>
            <span className={styles.nameUser}>{nameUser}</span>&nbsp;&nbsp;
          </div>
        ) : null}

        <div className="mr-">
          <LanguageSelector />
        </div>
        <ProfileMenu />
      </div>
    )
  }
}

export default injectIntl(TopBar)
