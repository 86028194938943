import actions from './actions'

const initialState = {
  id: '1', // ID
  city: 'Quqo BOGOTÁ', // BOGOTA
  id_provider: '8', // Provider
  retailer_id: 1,
  retailer_name: 'BODEGA BOGOTÁ',
  type_menu: "STORE"
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
