import axiosInstanceQuqo from './handlingPetitions'

const JSONParsebaseURL = JSON.parse(process.env.REACT_APP_API_URL_V2);
const baseURLUse = localStorage.getItem('base_url_use') || "prod";
const baseURL = JSONParsebaseURL[baseURLUse];

const urlProvider = `${baseURL}/admin/provider`

export function getProvidersService() {
  return new Promise((resolve, reject) => {
    axiosInstanceQuqo
      .get(urlProvider)
      .then((response) => resolve(response.data.data.ProviderAdmin))
      .catch(() => reject())
  })
}

export async function getProvidersAdminService() {
  const response = await axiosInstanceQuqo.get(`${urlProvider}/all`)
  return response.data.data.Provider
}

export async function getTotalProducts() {
  return new Promise((resolve) => {
    axiosInstanceQuqo
      .get(`${baseURL}/admin/products/totalProducts`)
      .then((response) => {
        resolve(response.data.data)
      })
  })
}

export async function createProviderServices(data) {
  const response = await axiosInstanceQuqo.post(urlProvider, data)
  return response.data.data.countries
}

export async function editProviderService(data, idProvider) {
  data.id = idProvider
  const response = await axiosInstanceQuqo.put(`${urlProvider}/${idProvider}`, data)
  return response
}

export async function getPoligonCoverageService(providerID) {
  const response = await axiosInstanceQuqo.get(`${urlProvider}/coverage/${providerID}`)
  return response.data.data
}

export async function updatePoligonCoverageService(data) {
  const response = await axiosInstanceQuqo.post(`${urlProvider}/coverage`, data)
  return response.data.data.countries
}

export function getProviderByIDService(providerID) {
  return new Promise((resolve, reject) => {
    axiosInstanceQuqo
      .get(`${urlProvider}/byID/${providerID}`)
      .then((response) => resolve(response.data.data))
      .catch(() => reject())
  })
}