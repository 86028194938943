import { all, takeEvery, put } from 'redux-saga/effects'
import actions from './actions'

export function* LOAD_CURRENT_CITY() {
  const cityCookie = localStorage.getItem('userCity')
    ? JSON.parse(localStorage.getItem('userCity'))
    : {}

  if (cityCookie) {
    yield put({
      type: 'city/SET_STATE',
      payload: {
        id: cityCookie.id,
        city: cityCookie.city,
        id_provider: cityCookie.id_provider,
        retailer_id: cityCookie.retailer_id,
        language: cityCookie.language,
        retailer_name: cityCookie.retailer_name,
        type_menu: cityCookie.type_menu,
        parent_provider_id: cityCookie.parent_provider_id,
        orders_bill_number: cityCookie.orders_bill_number,
        country: cityCookie.country,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_CURRENT_CITY, LOAD_CURRENT_CITY), LOAD_CURRENT_CITY()])
}
